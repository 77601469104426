import React, { forwardRef } from 'react';

import { fromJS } from 'immutable';
import { CheckboxField } from '@zambezi/formidable-components';
import Validation from '../../vb-components/Validation/Validation';
import { RULES_MAP, VALIDATION_RULES } from './validationConstants';
import { COPY, VALIDATION_MESSAGES, BRAND_CONST } from './landingtext';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe'
import * as CONFIG from '../../config.js';
import {MainHeading, SideImage} from '../containers/commonUI'
import NavigationContainer from './vb-navigationContainer'
import Accordion  from '@zambezi/sdk/accordion'
import { Icon } from '@zambezi/sdk/icons'
import $ from 'jquery';
import {track_page} from '../../vb-mi';

function VideoAccordionHeader(props, ref) {
    const { id, className, onClick, children, onKeyDown } = props
    return (
        <header
        className={"vb-video-accordion-header " + ` custom-header`}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ref={ref}
        id={id}
        tabIndex="0"
      >
          
          <div>
            <Icon name = {props.open ? "core-slider-decrease" : "core-slider-increase"} className="vb-icon-padding" ></Icon>
            <a href="javascript:void(0)" role="button" className="vb-video-accordion-header-text" >
                <span >{children}</span>            
            </a>
          </div>
          
      </header>
    )
  }  
  

class DisplayVideo extends React.Component {
    constructor(props) {
        super(props);
        var firstLineHeading = COPY.PAGE_DISPLAY_VIDEO_SEC_HEADING;
        var secondLineHeading = COPY.PAGE_DISPLAY_VIDEO_SEC_SUB_HEADING;
        if(window.isProductPageIsRequired === true)
        {
            firstLineHeading = COPY.PAGE_DISPLAY_VIDEO_SEC_HEADING;
            secondLineHeading = COPY.PAGE_STEP_3_OF_3_HEADING;
        }
        this.state = {       
          form: fromJS(this.props.location.state.form),
          brand : CONFIG.data.BRAND,
          firstLineHeading: firstLineHeading,
          secondLineHeading: secondLineHeading
        }
        this.img_name = CONFIG.data.BRAND.toLowerCase() + '/step3.' + CONFIG.data.PIC_FORMAT;
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.ariaInvalid = this.ariaInvalid.bind(this);


      } 
      ariaInvalid(id) {
        return this.isValid(id) ? undefined : true;
      }
      componentDidMount(){
        const { form } = this.state;

        const pagetitle=COPY.DISPLAY_VIDEO_PAGE_TITLE;
        document.title=pagetitle;
        
        if(form.getIn(['vbprodlist', 'value']) == undefined)
        {
          console.log("CUst Details Form  is not defined");
          this.props.history.replace({ pathname: '/' });
          return;
        }

        track_page(pagetitle);
        console.log('display Video Transcript');
        $(".vb-video-accordion-content").html(BRAND_CONST[this.state.brand].PAGE_DISPLAY_VIDEO_TRANSCRIPT);

      }
      onChange(name,value) {
        let { form } = this.state;
        console.log("val",arguments ,"VAlue",value);
        if(value){
           
                form=form.setIn([name, 'message'], '')
                $('input[name=vbprst]').attr('aria-invalid','false'); 
             
        }else{
                $('input[name=vbprst]').attr('aria-describedby','errorDescription-vbprst'); 
                $('input[name=vbprst]').attr('aria-invalid','true'); 
                form= form.setIn([name, 'message'], 'Please confirm before you proceed')
             
           
        }
        this.setState({
            form: form.setIn([name, 'value'], value)
          });
    }  
    onSubmit(e) {
        let { form } = this.state;
        const val=form.getIn(['vbprst', 'value']);

        console.log("val",val);
            if(val){
                    return true;
            }else{
                $('input[name=vbprst]').attr('aria-invalid','true'); 
                $('input[name=vbprst]').attr('aria-describedby','errorDescription-vbprst'); 
                this.setState({
                    form: form.setIn(['vbprst', 'message'], 'Please confirm before you proceed')
                  });
				  $('input:checkbox[name=vbprst]:first').attr('aria-checked','true').focus();
                return false;
            }
    }
isValid(id) {
    const { form } = this.state;
    //console.log('errormessage valid' + form.getIn([id, 'message']));
    return !form.getIn([id, 'message']);
}
     
    render() {
        const { form } = this.state;
        var displayVideoPage = this;
        return (
            <form id="displayVideoForm" >      

                <div className="zb-container-vb">
                    <div className='zb-column-is-12'>
                            <div className='zb-column-is-1'></div>
                            <div className='zb-column-is-8'>
                                <MainHeading firstLine= {this.state.firstLineHeading} secondLine = {this.state.secondLineHeading}/>
                                <section className='vb-video-section'>
                                    <h4 className="vb-video-help-text ">{COPY.PAGE_DISPLAY_VIDEO_SEC_TEXT}</h4>
                                    <div className= "vb-video-frame-outer">
                                        <Iframe url={CONFIG.data.VIDEO_URL}
                                        id="myId" title="Informational Video" className= "vb-video-frame" />
                                    </div>
                                    <div>
                                    
                                    </div>
                                    <Accordion className="vb-video-accordion" Header={forwardRef(VideoAccordionHeader)}>
                                            
                                        <dt>
                                            Video banking important information
                                        </dt>
                                            
                                        <div className="vb-video-accordion-content vb-video-accordion-content-bg"></div>
                                    </Accordion>

			                        <CheckboxField
                                        name="vbprst"
                                         label={COPY.PRIVACY_STMT_TWO}
                                         onChange={this.onChange}
					 className='vb-privacy-st-2'
                                         aria-invalid={ this.ariaInvalid('vbexistingcust') }  
                                         aria-describedBy="errorDescription-vbprst"    
                                        />
                                        {!this.isValid('vbprst') && (
                                            <Validation>
                                                {[form.getIn(['vbprst', 'message']),"errorDescription-vbprst"]}
                                            </Validation>
                                        )}
                                       
                                </section>                                                
                            </div>
                            
                            <div className='zb-column-is-3'>
                                <SideImage alt={"sp_drum"} title = {"sp_drum"} imgName= {this.img_name}/>
                            </div>
                    </div>          
                </div>
                <div className="zb-container-vb">
                    <NavigationContainer action="/RHRNMeeting" formdata={this.state.form} parentObject={this} /> 
                </div>
            </form >        
        );
    }

   
}
DisplayVideo.displayName = 'Display Video';
DisplayVideo.propTypes = {
    titles: PropTypes.array,
    onChange: PropTypes.func
};

export default DisplayVideo;

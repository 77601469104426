import React, { Component } from 'react';

export class Arrow extends Component {
  constructor() {
    super(...arguments);
    this.getClassNames = this.getClassNames.bind(this);
  }

  getClassNames() {
    return {
      holder: 'components-arrow-holder',
      arrow: 'components-arrow-arrow'
    };
  }

  render() {
    const classNames = this.getClassNames();

    return (
      <div {...this.props} className={classNames.holder}>
        <div className={classNames.arrow}></div>
      </div>
    );
  }
}

export default Arrow;

//import React from 'react';
export function initVBSession(CONFIG)
{
    if(CONFIG.ENV != "DEV")
    {
        console.log = function() {}
    }
    console.log("Inside initVBSession ");
    window.zoomMeetingStatus = 'ENDED';

    window.connect.ChatSession.setGlobalConfig({
        region: CONFIG.CARDS_CONNECT_REGION
    });
    window.AWS.config.region = CONFIG.REGION;
    window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
        IdentityPoolId: CONFIG.POOL
    });

    
    // Prepare to call Lambda function
    window.lambda = new window.AWS.Lambda({ region: CONFIG.REGION, apiVersion: '2015-03-31' });
    window.CONFIG = CONFIG;

    window.onpopstate = function(event) {
        console.log('onpopstate Inside');
       // window.history.forward(1);
       // window.history.forward(1);
        //this.alert("This operation is not permitted");
        //console.log("OnpopState");
        //window.history.pushState(null, null, window.location.pathname);
        
    };
    
       
    //window.lambda = lambda;
    //window.CONFIG = CONFIG;
    
}


export function initOnAppExit()
{
    window.onbeforeunload = function (event) {
        console.log('onBeforeUnload Inside');
        
         return "Are you sure do you want to exit?";
     };
 
}


/*
// Commented as agent status check is not required
export  function isAgentAvailable(CONFIG)
{
    
    var params = {
        FunctionName: CONFIG.AGENT_AVAILABILITY_STATUS,
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify({ "business": CONFIG.BUSINESS_FUNCTION, "subAccount": CONFIG.BRAND })
    };

    

    return new Promise((resolve, reject) => {
        window.lambda.invoke(params, function (err, result) {
            if (err) {
                resolve(err);
                console.log("error----"+err);
            } else {

                resolve(result)
                console.log("Agent Status Req : " + JSON.stringify(params));
                console.log("Agent Status Resp : "+JSON.stringify(result));
                
            }
        });
    });

    
}
*/
export  function getSsmParameters(CONFIG)
{
    
    var params = {
        FunctionName: CONFIG.GET_SSM_PARAMETER,
        InvocationType: 'RequestResponse',
        LogType: 'None',
        Payload: JSON.stringify({ "business": CONFIG.BUSINESS_FUNCTION, "subAccount": CONFIG.BRAND })
    };

    /*var status =  await window.lambda.invoke(params).promise();
    console.log('isAgentAvailable agentStatuspromise=' + status )

    return status;*/

    return new Promise((resolve, reject) => {
        window.lambda.invoke(params, function (err, result) {
            if (err) {
                resolve(err);
                console.log("error----"+err);
            } else {

                resolve(result)
                console.log("vb-getssmparameters-lambda Req : " + JSON.stringify(params));
                console.log("vb-getssmparameters-lambda Resp : "+JSON.stringify(result));
                
            }
        });
    });

    /*return promise.then(
        function (result) {
            return result.Payload
        },
        function (error) {
            console.log(error)
            return error
        }
    );*/
}


export function createChatSession(chatDetails) {
    var streamer = window.connect.ChatSession.create({
        chatDetails: chatDetails.data.startChatResult,
        type: "CUSTOMER"
    });
    callStream(streamer);

}

export function callStream(session) {
    session.connect().then((response) => {
        return response;
    }, (error) => {
        console.log("Unsuccessful connection " + JSON.stringify(error));
        return Promise.reject(error);
    });

    session.onConnectionEstablished((data) => {
        console.log("Established!");
    })

    session.onMessage((message) => {
        console.log("Received message: " + JSON.stringify(message));
    });

    session.onTyping((typingEvent) => {
        console.log("Received typing event: " + JSON.stringify(typingEvent));
    });

    session.onConnectionBroken((data) => {
        console.log("Connection broken.");
    });
}

export function getDeviceOS() {
    var os = (function () {
        var ua = window.navigator.userAgent.toLowerCase();

        return {
            isWindows: /windows nt/.test(ua),
            isAndroid: /android/.test(ua),
            isMac: /macintosh/.test(ua),
            isIOS: (/iPad|iPhone|iPod/.test(window.navigator.platform) ||
                (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)) &&
                !window.MSStream
        };
    }());

    return os;

}
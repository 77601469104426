import React from 'react';
import { withRouter } from 'react-router-dom';
import { COPY, BRAND_CONST } from './landingtext';
import { MobileImage, NotificationContent,NotificationLink} from '../containers/NotificationTemplate'
import * as CONFIG from '../../config'; 
import {track_page} from '../../vb-mi';

class ExitAppointment extends React.Component {

  constructor(props) {
    super(props);
    this.img_name = CONFIG.data.BRAND.toLowerCase() + '/exit.' + CONFIG.data.PIC_FORMAT;
    this.onSubmit = this.onSubmit.bind(this);
    //alert('exit constructor : '+window.zoomMeetingStarted);
    if(this.props.history.location.state)
	{
		this.apt_heading = COPY.PAGE_END_APPOINTMENT_HEADING;
		this.apt_content = COPY.PAGE_END_APPOINTMENT_CONTENT;
    this.pgtitle = COPY.EXIST_PAGE_TITLEZ;
	}
	else
	{
		this.apt_heading = COPY.PAGE_EXIT_APPOINTMENT_HEADING;
		this.apt_content = COPY.PAGE_EXIT_APPOINTMENT_CONTENT;
    this.pgtitle = COPY.EXIST_PAGE_TITLE;
	}
  }

  onSubmit()
  {
    
  }



  componentDidMount(){
    //const pagetitle=COPY.EXIST_PAGE_TITLE;
    document.title=this.pgtitle;
    track_page(this.pgtitle);
    document.querySelector('.zb-is-hidden-mobile').focus();
    /*alert('window.zoomMeetingStarted : '+window.zoomMeetingStarted);
    if(!(window.zoomMeetingStarted===true)){
      console.log("Manually navigated to exit");
      this.props.history.replace({ pathname: '/' });
      return;
    }
    window.zoomMeetingStarted = null; */
  }

  render() {
    
   
    
    return (
      <form id="exitPage" > 
        <MobileImage imageName={this.img_name}/>
        <div className="zb-container-vb">
          <NotificationContent mobileHeading={this.apt_heading} heading={this.apt_heading} 
          content={this.apt_content} alt={"Exit_ThumbsUp"} title = {"Exit_ThumbsUp"} imageName={this.img_name}  />
        </div>
        <div className="zb-container-vb">
              <NotificationLink label={COPY.PAGE_LEAVE_MEETING} action="#" />                     
        </div>     
      </form >
 

    );
  }
}


export default withRouter(ExitAppointment);


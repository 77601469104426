import React from 'react';
import { withRouter } from 'react-router-dom';
import { COPY , BRAND_CONST, ERROR_CODES} from './landingtext';
import { MobileImage, NotificationContent,NotificationLink} from '../containers/NotificationTemplate';
import * as CONFIG from '../../config'; 
import {track_page} from '../../vb-mi';
import { browserName, browserVersion } from "react-device-detect";

class BrowserIncompatible extends React.Component {

  constructor(props) {
    super(props);
    this.img_name = CONFIG.data.BRAND.toLowerCase() + '/incompatible_browser.' + CONFIG.data.PIC_FORMAT;
    this.onSubmit = this.onSubmit.bind(this);
    
  }
onSubmit(e) {
	}


  componentDidMount(){
    const pagetitle=COPY.BROWSER_REGRET_PAGE_TITLE;
    document.title=pagetitle;
    track_page(pagetitle);
    document.querySelector('.zb-is-hidden-mobile').focus();
    if(!(window.errorCode ===  ERROR_CODES.BROWSER_ERROR_CODE)){
      console.log("Navigated to High volume by browser");
      this.props.history.replace({ pathname: '/' });
      return;
    }
    window.errorCode = null;
  }
  render() {  
   
    return (
      <form id="vb-browser-incompatible-form" >  
      <MobileImage imageName={this.img_name}/>   
       <div className="zb-container-vb">
        <NotificationContent mobileHeading={COPY.PAGE_BROWSER_INCOMPATIBLE_HEADING} heading={COPY.PAGE_BROWSER_INCOMPATIBLE_HEADING}
         content={COPY.PAGE_BROWSER_INCOMPATIBLE_CONTENT} alt={"incompatible_browser"} title = {"incompatible_browser"} imageName={this.img_name}  />            
           
       </div>
       <div className="zb-container-vb">
           <NotificationLink label={COPY.PAGE_BOOKAPPOINTMENT} action={CONFIG.data.BOOK_APPOINTMENT_URL} />                   
       </div>
     </form >
    );
  }
}


export default withRouter(BrowserIncompatible);


import React, { Component } from 'react';
import Infobox from '../Infobox/Infobox';
import PropTypes from 'prop-types';

export class Validation extends Component {
  render() {
    const { children, ...other } = this.props;

    return (
      <Infobox {...other} className="components-validation">
        {children}
      </Infobox>
    );
  }
}

Validation.propTypes = {
  children: PropTypes.node
};

export default Validation;

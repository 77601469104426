import React from 'react';
import { withRouter } from 'react-router-dom';
import { COPY , BRAND_CONST, ERROR_CODES} from './landingtext';
import { MobileImage, NotificationContent, NotificationLink} from '../containers/NotificationTemplate'
import * as CONFIG from '../../config'; 
import {track_page} from '../../vb-mi';

class HighVolume extends React.Component {

  constructor(props) {
    super(props);
    this.img_name = CONFIG.data.BRAND.toLowerCase() + '/high_volume_regret.' + CONFIG.data.PIC_FORMAT;
    this.onSubmit = this.onSubmit.bind(this);

    
  }

  onSubmit(){
    
  }

  componentDidMount(){
    const pagetitle=COPY.AGENT_REGRET_PAGE_TITLE;
    document.title=pagetitle;
    track_page(pagetitle);
    document.querySelector(".zb-is-hidden-mobile").focus();
    if(!(window.errorCode ===  ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE)){
      console.log("Manually navigated to High volume ");
          this.props.history.replace({ pathname: '/' });
          return;
    }
    window.errorCode = null;
  }
  render() {
    let bookClick = () => {
      window.open(CONFIG.data.BOOK_APPOINTMENT_URL, "_self");
    };
    return (<div id="highvol">
      <form id="highVolumePage" >
          <MobileImage imageName={this.img_name}/>
          <div className="zb-container-vb">
         
		<div className='zb-column-is-12' >
        <div className='zb-column-is-1'></div>
        <div className='zb-column-is-8'>
          <section>
               <div id="heading">
                  <h1 className="zb-is-hidden-mobile vb-app-main-heading-l2">{COPY.PAGE_HIGH_VOLUME_HEADING}</h1>
                  <h1 className="zb-is-visible-mobile-block vb-m-app-main-heading-l2">{COPY.PAGE_HIGH_VOLUME_HEADING}</h1>
                </div>  
                  <div className="vb-waiting-page-content">
				   {COPY.PAGE_HIGH_VOLUME_CONTENT1} <br/><br/>
                   {COPY.PAGE_HIGH_VOLUME_CONTENT2} <br/><br/>
                   {COPY.PAGE_HIGH_VOLUME_CONTENT3}
                   <b>{COPY.PAGE_HIGH_VOLUME_CONTENT4}</b>
                   {COPY.PAGE_HIGH_VOLUME_CONTENT5}
                   <b>{COPY.PAGE_HIGH_VOLUME_CONTENT6}</b>
                   {COPY.PAGE_HIGH_VOLUME_CONTENT7}
                  </div><br/>
                  <button type="button" onClick={bookClick} className="vb-waiting-button">Book your chat</button>
          </section>      
          <br/><br/><br/>       
        </div>
        <div className='zb-column-is-3' tabIndex="-1" >                    
              <div className='zb-is-hidden-mobile vb-spinner-container vb-l-spinner' tabIndex="-1">
                <div className='vb-spinner' tabIndex="-1">                        
                    <div className="vb-image-content vb-image-content-padding1">
                      <div className= "vb-atom-image">
                        <img src={'./static/'+this.img_name} alt = {"high_volume_regret"} title = {"high_volume_regret"} className="vb-atom-image"></img>
                      </div>
                    </div>
                </div>
              </div>
        </div>
      </div>
          </div> 

      </form > </div>
    );
  }
}


export default withRouter(HighVolume);



import React from 'react';
import { withRouter } from 'react-router-dom';
import { COPY , BRAND_CONST, ERROR_CODES} from './landingtext';
import { MobileImage, NotificationContent,NotificationLink} from '../containers/NotificationTemplate'
import * as CONFIG from '../../config'; 
import {track_page} from '../../vb-mi';
import $ from 'jquery';

class GenericRegret extends React.Component {

  constructor(props) {
    super(props);
    this.img_name = CONFIG.data.BRAND.toLowerCase() + '/generic_regret.' + CONFIG.data.PIC_FORMAT;
    this.onSubmit = this.onSubmit.bind(this);
    
  }

  onSubmit(){
    
  }

  componentDidMount(){
    $('#zmmtg-root').hide();
    const pagetitle=COPY.GENERIC_REGRET_PAGE_TITLE;
    document.title=pagetitle;
    track_page(pagetitle);
    document.querySelector('.zb-is-hidden-mobile').focus();
    if(!(window.errorCode ===  ERROR_CODES.SYSTEM_ERROR_CODE)){
      console.log("Manually navigated to Generic Regret page...");
      window.errorCode = null;
      this.props.history.replace({ pathname: '/' });
      return;
    }
    window.errorCode = null;

  }

  render() {
     
    
    return (
      <form id="generic-regret-page" >     
       <MobileImage imageName={this.img_name}/>  
      
        <div className="zb-container-vb">
        <NotificationContent mobileHeading={COPY.PAGE_GENERIC_REGRET_HEADING} heading={COPY.PAGE_GENERIC_REGRET_HEADING}
         content={COPY.PAGE_GENERIC_REGRET_CONTENT} alt={"generic_regret"} title = {"generic_regret"} imageName={this.img_name}  />
            
        </div>
        <div className="zb-container-vb">
            <NotificationLink label={COPY.PAGE_BOOKAPPOINTMENT} action={CONFIG.data.BOOK_APPOINTMENT_URL} />                                          
        </div>
      </form >
    );
  }
}


export default withRouter(GenericRegret);


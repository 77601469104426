import React from 'react';
import * as CONFIG from  './../src/config';
import { BRAND_CONST } from './pages/containers/landingtext';
export default function header(props) {
  var path = './static/' + CONFIG.data.BRAND.toLowerCase() + '/logo.' + CONFIG.data.PIC_FORMAT;
  return (
    <header className="vb-header">
      <div className="zb-container-vb">
        <div className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className='zb-column-is-11'>
                  <img src={path} alt={props.brand} title={props.brand} className='vb-header-img'></img>
                </div>
        </div>
      </div>
    </header>
    
  );
}

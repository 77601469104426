import React from 'react';
//import '@zoomus/websdk/dist/css/bootstrap.css';
//import '@zoomus/websdk/dist/css/react-select.css';
import $ from 'jquery';
import * as CONFIG from './../../config.js';
import {track_page} from '../../vb-mi';
import * as TOOLS from './../../tool';
import { COPY, VALIDATION_MESSAGES, PROD_LIST,ERROR_CODES } from './landingtext';

import { ZoomMtg } from '@zoom/meetingsdk';
class VBZoomCall extends React.Component {
    constructor(props) {
        super(props);
        this.lambda = window.lambda;
        this.meetingInfo = props.meetingInfo;
        this.participantName = props.participantName;
        this.curPage = props.parentObj;
    }

    componentDidMount() {
        const pagetitle=COPY.ZOOM_PAGE_TITLE;
        document.title=pagetitle;
        track_page(pagetitle);
        this.initiate();
    }

    render() {
        $('#zmmtg-root').show();
        return (
                <div id='vbZoomInstance'>
                    <link type="text/css" rel="stylesheet" href= {window.location.origin + CONFIG.data.ZOOM_WEBSDKLIB_PATH + "dist/css/bootstrap.css"} />
                    <link type="text/css" rel="stylesheet" href={window.location.origin + CONFIG.data.ZOOM_WEBSDKLIB_PATH +  "dist/css/react-select.css"} />
                </div>
        );
    }

    initiate() {

       
        ZoomMtg.preLoadWasm()
        ZoomMtg.prepareWebSDK()
        
        var thisObject = this;
        var meetConfig = {
            meetingNumber: this.meetingInfo.zoomMeetingID,
            userName: this.participantName,
            passWord: "",
            china: false
        };


        var params = {
            FunctionName: CONFIG.data.GENERATE_SIGNATURE,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: JSON.stringify({ meetingData: {meetingNumber: this.meetingInfo.zoomMeetingID}, "subAccount": CONFIG.data.BRAND })
        };

        var retryCount =0;

        window.lambda.invoke(params, function (err, result) {
            if (err) {
                //prompt(err);
                console.log('Exception Invoking GENERATE_SIGNATURE Error' + err);
            } else {
                console.log("GENERATE_SIGNATURE Req : " + JSON.stringify(params));
                console.log("GENERATE_SIGNATURE Resp : " + JSON.stringify(result));
                let getSignatureResponse = JSON.parse(result.Payload);
                console.log("getSignature" + getSignatureResponse)
                thisObject.zoomInitAPI(meetConfig, getSignatureResponse);
            }
        });
    }

    zoomInitAPI(meetConfig, getSignatureResponse)
    {
        var thisObject = this;
        ZoomMtg.init({
            isShowJoiningErrorDialog:false,
            leaveUrl: window.location.origin + "/" + CONFIG.data.RE_ROUTE_PAGE_URL,
            patchJsMedia: true,
            isSupportAV: true,
            success: function () {
                thisObject.zoomJoinAPI(meetConfig,getSignatureResponse,  0);          
            },
            error: function (res) {
                console.log(res);
                window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;
                thisObject.curPage.props.history.replace({ pathname: '/genericregret' });
            }
        });
    }

    zoomJoinAPI(meetConfig, getSignatureResponse, retryCount){
        var thisObject = this;
        ZoomMtg.join(
            {
                meetingNumber: meetConfig.meetingNumber,
                userName: meetConfig.userName,

                signature: getSignatureResponse.sig,
                sdkKey: getSignatureResponse.sdkKey,
                userEmail: 'email@gmail.com',
                passWord: meetConfig.passWord,
                success: function (res) {
                    $('#nav-tool').hide();
                    console.log('join meeting success');
                },
                error: function (res) {
                    console.log(" Join meeting failed retrying retryCount =" + retryCount);
                    if(retryCount++ <=   CONFIG.data.MAX_ZOOM_JOIN_ATTEMPTS)
                    {
                        setTimeout(function(){
                            thisObject.zoomJoinAPI(meetConfig,getSignatureResponse,  retryCount);                      
                        }, CONFIG.data.ZOOM_JOIN_TIMER);
                    }
                    else
                    {
                        window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;                        
                        thisObject.curPage.props.history.replace({ pathname: '/genericregret' });
                    }
                    console.log(res);                                    
                   
                }
            }
        );

        ZoomMtg.inMeetingServiceListener('onUserUpdate', function (data) {
            console.log('inMeetingServiceListener onUserUpdate', data);
        });
    }

}
export default VBZoomCall;

import React from 'react';
import { fromJS } from 'immutable';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { RULES_MAP, VALIDATION_RULES } from './validationConstants';
import { RadioGroupField , SelectField, InputField} from '@zambezi/formidable-components';
import { COPY, VALIDATION_MESSAGES, BRAND_CONST, ERROR_CODES } from './landingtext';
import {MainHeading, SideImage} from '../containers/commonUI'
import NavigationContainer from './vb-navigationContainer'
import Validation from '../../vb-components/Validation/Validation';
import {track_page} from '../../vb-mi';
import * as CONFIG from "./../../config";
import PopupContainer from './vb-popupContainer';
const FORM_ORDER = ['vbtitle','vbfullName','vbphoneNumber','vbpostalCode'];


class CustomerDetails extends React.Component {
    constructor(props) {
      super(props);
    //  const { form } = this.state;
    var prodDefaultValue = null;
    if(this.props.isValidationErr){
      if(this.props.errorCode === ERROR_CODES.BROWSER_ERROR_CODE){
        window.errorCode = ERROR_CODES.BROWSER_ERROR_CODE
        this.props.history.replace({ pathname: '/incompatible'});
      }
      else if(this.props.errorCode === ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE){
        window.errorCode =  ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE;
        this.props.history.replace({ pathname: '/highvolume'});
      }
      else{
        window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;
        this.props.history.replace({ pathname: '/genericregret'});
      }
    
    }
    if(props.isProductPageIsRequired === false){
      prodDefaultValue=BRAND_CONST[this.props.brand].PRODUCT_LIST[0].value ;
      this.state = {       
        form: fromJS({
          vbprodlist: {
            value: prodDefaultValue,
            message: undefined,
            touched: false,
            rendered: true
          },
          vbtitle: {
            value: undefined,
            message: null,
            touched: false,
            rendered: true
          },
          vbfullName: {
            value: undefined,
            message: undefined,
            touched: false,
            rendered: true
          },
          vbpostalCode: {
            value: undefined,
            message: undefined,
            touched: false,
            rendered: false
          },
          vbphoneNumber: {
            value: undefined,
            message: undefined,
            touched: false,
            rendered: true
          },
          vbexistingcust: {
            value: undefined,
            message: undefined,
            touched: false,
            rendered: false
          },
          vbprst: {
            value: undefined,
            message: undefined,
            touched: false,
            rendered: false
          }
        }),
        brand : CONFIG.data.BRAND,
        heading1 : COPY.PAGE_PRODUCTSEC_HEADING,
        heading2 : COPY.PAGE_CUSTINPUT_SEC_SUB_HEADING
      }
    }
    else{
      this.state = {       
        form: fromJS(this.props.location.state.form),
        brand : CONFIG.data.BRAND,
        heading1 : COPY.PAGE_HEADING_LETS_SETUP,
        heading2 : COPY.PAGE_STEP_2_OF_3_HEADING
      }
    }
      this.img_name = CONFIG.data.BRAND.toLowerCase() + '/step2.' + CONFIG.data.PIC_FORMAT;
      console.log('This is CustomerDetails Log');
      console.log(this.state.brand);
      this.onChange = this.onChange.bind(this);
  this.onBlur=this.onBlur.bind(this);
  this.onSubmit=this.onSubmit.bind(this);
  this.ariaInvalid = this.ariaInvalid.bind(this);
    }
    ariaInvalid(id) {
      return this.isValid(id) ? undefined : true;
    }

    radioButtonFocus = (e,v) => {
      console.log('radio button focused value : '+v);
      $("input[name='vbexistingcust'][value='"+v+"']").parent().addClass('zb-input-label-name vb-radiobutton-focus');
    }
  
    radioButtonBlur = (e) => {
      console.log('radio button blur');
      $("input[name='vbexistingcust']").parent().removeClass().addClass('zb-input-label-name');
    }

onChange(name,value) {
      let { form } = this.state;
   console.log('target', value);
     if(name === 'vbexistingcust'){           
                  form=form.setIn([name, 'message'], '');
                  $('input:radio[name=vbexistingcust]:first').prev().attr('class','zb-radio-button');               
          }
  if( name === 'vbtitle'){

          if(value === ''){
           
                    form=form.setIn([name, 'message'], VALIDATION_MESSAGES.TITLE_REQUIRED);
          }
          else{
            form=form.setIn([name, 'message'], '');
          }
                 
            }
    if ( name === 'vbfullName'){
      if(value.length >1){

        form=form.setIn([name, 'message'], '');
      }
    }
  

   if( name === 'vbexistingcust' && value === 'No'){
        console.log("vbexistingcust  ",name,'value',value); 
        $('#vbpostalCode').val('');
        form = form.setIn(['vbpostalCode', 'value'], '').setIn(['vbpostalCode','message'],'');
        
      }
      this.setState({
        form: form.setIn([name, 'value'], value)
    });

  }
  onSubmit(e) {
    
    let { form } = this.state;
    form= this.validateField("vbexistingcust", form);
    var firstInvalidFieldId=null;
    let validationFlag=true;
    if(form.getIn(['vbexistingcust', 'message']) == undefined){
      FORM_ORDER.map(value => {
        form= this.validateField(value, form);
        if(form.getIn([value, 'message']) != undefined){
          if(firstInvalidFieldId === null){
           // alert('value : '+value);
            $('#'+value).focus();
            if(value === 'vbtitle')
            {
              $('#Submit').blur();
             // $('#'+value).parent().attr('tabindex','-1');
             // $('#'+value).parent().focus();
            }
            firstInvalidFieldId = value;
          }
          validationFlag=false;
        }
      })
     
    } else{
     // $("input:radio[name=groupName][disabled=false]:first").attr('checked', true);

      $('input:radio[name=vbexistingcust]:first').prev().attr('class','zb-radio-button zb-radio-button-is-error');
	    $('input:radio[name=vbexistingcust]:first').attr('aria-checked','true').focus();
      $('input:radio[name=vbexistingcust]').attr('aria-describedby','errorDescription-vbexistingcust'); 
      validationFlag=false;
    }

    this.setState({ form });
    console.log("message"+ this.isValid('vbexistingcust'));

    if(validationFlag){
      return true;
    }
    else{
     // $('#Submit').blur();
        
      return false;
    }
  }

  onBlur(name,value) {
    let  { form } = this.state;
    console.log("test",name,value);
     form= this.validateField(name, form);
     this.setState({ form });
      if( name === 'vbexistingcust'){
      $("input[name='vbexistingcust']").parent().removeClass().addClass('zb-input-label-name');
     }

    // this.setState({
    //     form: this.validate(form, name)
    // });
}
componentDidMount(){
  const pagetitle=COPY.ABOUT_YOU_PAGE_TITLE;
  document.title=pagetitle;
  const { form } = this.state;
  console.log("form",form);
  if( window.isProductPageIsRequired === true){
    $('#popUpDiv').hide();
    $('.vb-header').show();
		$('.zb-container-vb').show();
		$('.vb-footer-line').show();
		$('.vb-footer-content').show();
   if(form.getIn(['vbprodlist', 'value']) == undefined)
   {
     console.log("Cust Details Form  is not defined");
     this.props.history.replace({ pathname: '/' });
     return;
   }
  }
  track_page(pagetitle);

 
}
isValid(id) {
  const { form } = this.state;
  return !form.getIn([id, 'message']);
}
validate(form, id) {
  return id ? this.validateField(id, form) : this.validateForm(form);
}
validateForm(form) {
  form.keySeq().forEach(key => {
      if (form.getIn([key, 'rendered'])) {
          form = this.validateField(key, form);
      }
  });
  return form;
}
getClassName(id,className) {
  //  console.log('getclasname' + id);
  return  this.isValid(id) ? className : (className + ' zb-input-is-error');
}
validateField(id, form) {
  let message, rules, validation, value, rendered;

  rules = RULES_MAP[id];
  value = form.getIn([id, 'value']);
  console.log("value",value);
  console.log("id",id);

  $('#'+id).attr('aria-describedby',('errorDescription-'+id)); 
  rendered = form.getIn([id, 'rendered']);
  console.log("render",rendered);

  if (true) {
      validation = rules
          .map(id => VALIDATION_RULES()[id])
          .find(rule => rule.test(value));
          console.log("validation",validation);

      message = validation
          ? VALIDATION_MESSAGES[validation.messageCopyKey]
          : undefined;
  }
  console.log("message",message);
  if(message === undefined){
    $('#'+id).attr('aria-invalid','false');
    if(id ==='vbexistingcust'){
      $('input:radio[name=vbexistingcust]').attr('aria-invalid','false');
    }
  }else{
    $('#'+id).attr('aria-invalid','true');
    if(id ==='vbexistingcust'){
      $('input:radio[name=vbexistingcust]').attr('aria-invalid','true');
    }
  }
  return form
      .setIn([id, 'message'], message)
      .setIn([id, 'miKey'], 'test')
      .setIn([id, 'touched'], true);

}
    render()
    {
      const { form } = this.state;

      return (
        <form id="custInputForm" >   
         <div id="popUpDiv" ><PopupContainer /></div>  
          <div className="zb-container-vb">       
            <div className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className='zb-column-is-8'>
                <section>
                 <h1  className="vb-app-main-heading-input1">{this.state.heading1}</h1>
                 <h3  className="vb-app-sub-heading-input">{this.state.heading2}</h3>
               </section>
                   
                    <section >
                      <div>
                        <div className = "vb-app-content">
                          <label>{BRAND_CONST[this.state.brand].EXISTING_CUSTOMER_LABEL}</label>
                        </div>
                        <RadioGroupField
                            aria-describedBy="errorDescription-vbexistingcust"
                            className ="vb-rbg-existing-cust vb-app-content2"  
                            name="vbexistingcust"
                            id="vbexistingcust"                            
                            values={COPY.EXISTING_CUSTOMER}
                            onChange={this.onChange}      
                            onBlur={this.onBlur} 
                            onFocus={this.radioButtonFocus}  
                            aria-invalid={ this.ariaInvalid('vbexistingcust') }   
                                              
                        />
                        {!this.isValid('vbexistingcust') && (
                                    <Validation>
                                        {[form.getIn(['vbexistingcust', 'message']),"errorDescription-vbexistingcust"]}
                                    </Validation>
                                )}
                        
                      </div>
                    </section>
                </div>
                <div className='zb-column-is-3'>
                      <SideImage alt={"sp_book"} title = {"sp_book"} imgName= {this.img_name}/>
                </div>
              </div>

              <div className='zb-column-is-12'>
                  <div className='zb-column-is-1'></div>
                  <div className='zb-column-is-11'>
                  <section className={form.getIn(['vbexistingcust', 'value']) == undefined ? 'vb-postcode-sec' : ''}>
                      <div id='vb-cust-info'>                        
                        <div className="vb-app-subheading2">
                          Let’s check your details first
                        </div>
                        <div className="vb-input-elt-padding vb-cust-input">
                          <div className="vb-cust-input">
                            <label htmlFor="vbtitle">{COPY.TITLE_LABEL}</label>
                          </div>                  

                          <SelectField className = {this.getClassName("vbtitle","vb-sel-title  vb-input-elt-ht")}
                             
                              name="vbtitle"
                              id="vbtitle" 
                              value = {COPY.TITLE_LIST[0].value}                  
                              values={COPY.TITLE_LIST}
                              onChange={this.onChange}
                              onBlur={this.onBlur} 
							                onFocus={this.onChange}
                              aria-invalid={ this.ariaInvalid('vbtitle') } 
                              aria-describedBy="errorDescription-vbtitle"     
                          />
                            {!this.isValid('vbtitle') && (
                                    <Validation>
                                        {[form.getIn(['vbtitle', 'message']),"errorDescription-vbtitle"]}
                                    </Validation>
                                )}
                        </div>
                        <div className="vb-cust-input vb-input-elt-padding">       
                          <div className="vb-cust-input">
                            <label htmlFor="vbfullName">{COPY.FULLNAME_LABEL}</label>
                          </div>                  
                          <InputField
                              id="vbfullName"
                              name="vbfullName"
                              type="text"                             
                              onChange={this.onChange}
                              onBlur={this.onBlur}
                              className= {this.getClassName("vbfullName","vb-cust-name")}
                             inputProps={{ maxLength: 40 }}
                              aria-invalid={ this.ariaInvalid('vbfullName') } 
                              aria-describedBy="errorDescription-vbfullName"  
                          />
                          {!this.isValid('vbfullName') && (
                                    <Validation>
                                        {[form.getIn(['vbfullName', 'message']),"errorDescription-vbfullName"]}
                                    </Validation>
                                )}
                        </div>

                        <div className="vb-cust-input ">
                          <div className="vb-cust-input vb-input-elt-padding"><label htmlFor="vbphoneNumber">{COPY.PHONENUMBER_LABEL}</label></div>
                              <InputField                            
                                id="vbphoneNumber"
                                name="vbphoneNumber"
                                type="text"                    
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                                className= {this.getClassName("vbphoneNumber","vb-cust-phone")}
								inputProps={{ maxLength: 15 }}
                                aria-invalid={ this.ariaInvalid('vbphoneNumber') 
                              } 
                              aria-describedBy="errorDescription-vbphoneNumber"  
                              />
                              {!this.isValid('vbphoneNumber') && (
                                    <Validation>
                                        {[form.getIn(['vbphoneNumber', 'message']),"errorDescription-vbphoneNumber"]}
                                    </Validation>
                                )}
                          
                          <div id="vb-postcode-sec" className={form.getIn(['vbexistingcust', 'value']) == 'No' ? 'vb-postcode-sec' : ''}>
                            <div className="vb-cust-input vb-input-elt-padding">
                              <label htmlFor="vbpostalCode">{COPY.POSTCODE_LABEL}</label></div>
                                <InputField                              
                                  id="vbpostalCode"
                                  name="vbpostalCode"
                                  type="text"                    
                                  onChange={this.onChange}
                                  onBlur={this.onBlur}
                                  className={this.getClassName("vbpostalCode","vb-cust-postcode")}
								  inputProps={{ maxLength: 8 }}
                                  value={form.getIn(['vbpostalCode', 'value'])}
                                  aria-describedBy="errorDescription-vbpostalCode"  
                                />
                                {!this.isValid('vbpostalCode') && (
                                    <Validation>
                                        {[form.getIn(['vbpostalCode', 'message']),"errorDescription-vbpostalCode"]}
                                    </Validation>
                                )}
                     
                          </div>
                        </div>                      
                      </div>       
                    </section>                  
                  </div>                
              </div>
                            
          </div>
          <div className="zb-container-vb">
             <NavigationContainer action="/displayVideo" formdata={this.state.form} parentObject={this} /> 
          </div>
        </form >         
      );
    }
  
}
  
export default withRouter(CustomerDetails);
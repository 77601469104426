import React, { Component } from 'react';
import Arrow from '../Arrow';
import PropTypes from 'prop-types';

export class Infobox extends Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const { children, className = '', ...props } = this.props;

    return (
      <div {...props} className={className}>
        <div className="components-infobox-container">
          <Arrow className="components-infobox-arrow" />
          <span id={children[1]} className="components-infobox-content">{children[0]}</span>
        </div>
      </div>
    );
  }
}

Infobox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Infobox;

import React from 'react';
import $ from 'jquery';


class MobileImage extends React.Component {
    constructor(props) {
      super(props);
      this.imageName = props.imageName;
      
    }
    
    render()
    {
      return (
        <div className= 'zb-is-visible-mobile-block zb-is-visible-tablet-block vb-m-spinner'>
            <div className="zb-container-vb">            
              <div className='zb-column-is-12'>
                  <div className='vb-spinner-container'>
                      <div className='vb-spinner'>
                          <div className="vb-image-content vb-image-content-padding1">
                            <div className= "vb-atom-image">
                              <img src={'./static/'+this.imageName} className="vb-atom-image"></img>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>            
      );
    }
  
  }


  class NotificationContent extends React.Component{
    constructor(props) {
        super(props);
        this.imageName = props.imageName;
        this.mobileHeading= props.mobileHeading;
        this.heading = props.heading;
        this.content= props.content;
        
      }

      componentDidMount(){
          $('.vb-waiting-page-content').html(this.content);
          $('#heading').focus();
      }

      render(){
        return(
        <div className='zb-column-is-12'>
        <div className='zb-column-is-1'></div>
        <div className='zb-column-is-8'>
          <section>
               <div id="heading">
                  <h1 className="zb-is-hidden-mobile vb-app-main-heading-l2">{this.mobileHeading}</h1>
                  <h1 className="zb-is-visible-mobile-block vb-m-app-main-heading-l2">{this.heading}</h1>
                </div>  
                  <div className="vb-waiting-page-content">
                  
                  </div>
          </section>                           
        </div>
        <div className='zb-column-is-3' tabIndex="-1" >                    
              <div className='zb-is-hidden-mobile vb-spinner-container vb-l-spinner' tabIndex="-1">
                <div className='vb-spinner' tabIndex="-1">                        
                    <div className="vb-image-content vb-image-content-padding1">
                      <div className= "vb-atom-image">
                        <img src={'./static/'+this.imageName} alt = {this.props.alt} title = {this.props.title} className="vb-atom-image"></img>
                      </div>
                    </div>
                </div>
              </div>
        </div>
      </div>
        );
      }
  }

  class NotificationLink extends React.Component{

    constructor(props) {
      super(props);
      this.action = props.action;
      this.label= props.label;
    
    }

    linkFocus = (e) => {
      console.log('link focused');
     // $('.zb-cancel-primary').attr('style',' border:2px solid #1d7b8a;padding:3px;');
      $('.zb-cancel-primary').addClass('vb-link-focus');    //atatr('style',' border:2px solid #1d7b8a;padding:3px;');
    }
  
    linkBlur = (e) => {
      console.log('link blur');
      $('.zb-cancel-primary').removeClass('vb-link-focus');
    }

    closeVideoBanking = (e) => {
      var url= this.props.action;
      //alert('The link was clicked.');
      if(url === "#"|| url === null || url === undefined || url === "" ){
        e.preventDefault();      
        window.close();
      }
     
    };

    render(){
      return(
        <div className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className ="zb-column-is-11">
                <div className="vb-bottom-section2">
                  <div className="vb-book-appointment">                    
                    <a href={this.props.action} onFocus={this.linkFocus} onBlur={this.linkBlur}  onClick={this.closeVideoBanking} role="button" title={this.props.label} className="zb-cancel-primary">{this.props.label}</a>
                  </div>
                </div>                  
                </div>
            </div> 
      );

    }
  }


  export { MobileImage, NotificationContent, NotificationLink};
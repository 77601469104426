import '@babel/polyfill/dist/polyfill.min'
import React from 'react'
import ReactDOM from 'react-dom'

// Prepare to work with WebSDK: http://websdk.rbsgrp.net/
import '@zambezi/sdk-themes/zb-rbs-nonpersonal-standard/theme.min.css';
import '@zambezi/sdk-themes/zb-rbs-nonpersonal-standard/icons';
import '@zambezi/sdk-themes/colours/zb-natwest/colours.css';
import '@zambezi/sdk-themes/zb-rbs-nonpersonal-standard/theme.min.css';
import '@zambezi/sdk-themes/zb-natwest-nonpersonal-standard/theme.min.css';


//import '@zambezi/formidable-components/dist/formidable-components.min.css'
import {initVBSession,isAgentAvailable} from './../src/common';
import * as CONFIG from "./../src/config.js";
// Load application specific parts
import './css/index.css'


import App from './App'
import * as serviceWorker from './serviceWorker'

initVBSession(CONFIG.data);
//var agentStatuspromise =  isAgentAvailable(CONFIG.data);

//agentStatuspromise.then(response => {
//    console.log('index.js agentStatuspromise=' + response.Payload );
//    window.isAgentAvailable= JSON.parse(response.Payload).agentsAvailableFlag;
    
    ReactDOM.render(
        <App />,
        document.getElementById('root')
    );

//});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

export const COPY = {
  PRODUCTION_PAGE_TITLE:"Video banking product selection",
  PRODUCTION_SELECTION_TITLE: "Product Selection",
  ABOUT_YOU_PAGE_TITLE:"About You Page",
  DISPLAY_VIDEO_PAGE_TITLE:"Important Information Display Video Page ",
  ZOOM_PAGE_TITLE:'Right Here Right Now Zoom Appointnment',
  WAITING_ROOM_PAGE_TITTLE:"Waiting Room For Video Appointment",
  EXIST_PAGE_TITLE:"Appointment Completed, Close Window",
  EXIST_PAGE_TITLEZ:"Appointment Started, Close Window",
  AGENT_REGRET_PAGE_TITLE:"Agent Unavailability Regret Page",
  BANK_HOURS_REGRET_PAGE_TITLE:"Video Banking Bank Hours Regret Page ",
  BROWSER_REGRET_PAGE_TITLE:"Browser Incompatibility Regret Page", 
  GENERIC_REGRET_PAGE_TITLE : "Application Unavailable, Regret Page",
  PAGE_HEADING_LETS_SETUP: "Let’s get you set up",
  PAGE_PRODUCTSEC_HEADING: "Let’s get you set up for a Financial Health Check",
  PAGE_PRODUCTSEC_SUB_HEADING: 'Step 1 of 2',
  PAGE_CUSTINPUT_SEC_SUB_HEADING: "Step 1 of 2",
  PAGE_DISPLAY_VIDEO_SEC_HEADING : "Important information",
  PAGE_DISPLAY_VIDEO_SEC_SUB_HEADING: "Step 2 of 2",
  PAGE_STEP_1_OF_3_HEADING: "Step 1 of 3",
  PAGE_STEP_2_OF_3_HEADING: "Step 2 of 3",
  PAGE_STEP_3_OF_3_HEADING: "Step 3 of 3",
  PAGE_DISPLAY_VIDEO_SEC_TEXT:"Take a look at our video before your meeting, so you know what to expect.",
  PAGE_PRODUCTSEC_IMAGE_PATH: './Atoms_XL.png',
  PAGE_VIDEO_IMAGE_PATH: './Video_Image.png',
  PAGE_WAITING_ROOM_HEADING: "We’re just waiting for a colleague to become available",
  PAGE_WAITING_ROOM_HEADING_MOBILE: "We’re just waiting for a colleague to become available",
  PAGE_WAITING_3MIN_HEADING: "We can see that you’ve been waiting for 3 minutes - you can continue to wait to speak to us now or choose a date and time that suits you",
  PAGE_WAITING_3MIN_HEADING_MOBILE: "We can see that you’ve been waiting for 3 minutes - you can continue to wait to speak to us now or choose a date and time that suits you",
  PAGE_WAITING_ROOM_CONTENT1:  "We’ll put you through as soon as a colleague becomes available.",
  PAGE_WAITING_ROOM_CONTENT2:"Please don’t refresh this page or close it – doing this will end your session.",
  PAGE_WAITING_ROOM_BOOK_SUBHEADING:"Can’t talk right now?",
  PAGE_WAITING_ROOM_BOOK_CONTENT1:"Book a chat with our advisers at a time that suits you. Our colleagues are available ",
  PAGE_WAITING_ROOM_BOOK_CONTENT2:"Monday to Saturday: 8:00-20:00",
  PAGE_WAITING_ROOM_BOOK_CONTENT3:", and ",
  PAGE_WAITING_ROOM_BOOK_CONTENT4:"Sunday: 9:00-17:00",
  PAGE_WAITING_ROOM_BOOK_CONTENT5:", subject to availability.",
  PAGE_WAITING_ROOM_BOOK_APPOINTMENT:'Book your chat',
  PAGE_CALL_BEGIN_HEADING:"Your video banking call is about to begin",
  PAGE_CALL_BEGIN_CONTENT1:"Your banker for today’s video call is ",
  PAGE_CALL_BEGIN_CONTENT2:" knows you’re waiting.",
  PAGE_CALL_BEGIN_CONTENT3:" will open a secure Zoom session to launch your video banking appointment.",
  PAGE_EXIT_APPOINTMENT_HEADING:'Your video banking session has now finished',
  PAGE_EXIT_APPOINTMENT_LEAVE_MEETING:'Close Window',
  PAGE_EXIT_APPOINTMENT_CONTENT:"We hope you’re pleased with how it went.",
  PAGE_END_APPOINTMENT_HEADING:'Your video banking session will now take place over Zoom',
  PAGE_END_APPOINTMENT_LEAVE_MEETING:'Close Window',
  PAGE_END_APPOINTMENT_CONTENT:"Thank you for banking with us.",
  PAGE_HIGH_VOLUME_HEADING:"We don’t want to keep you waiting",
  PAGE_HIGH_VOLUME_CONTENT1:"Sorry, all our colleagues are busy helping other customers right now.",
  PAGE_HIGH_VOLUME_CONTENT2:"You can choose a future date and time that suits you.",
  PAGE_HIGH_VOLUME_CONTENT3:"Speak to a colleague ",
  PAGE_HIGH_VOLUME_CONTENT4:"Monday to Saturday: 8:00-20:00",
  PAGE_HIGH_VOLUME_CONTENT5:", and ",
  PAGE_HIGH_VOLUME_CONTENT6:"Sunday: 9:00-17:00",
  PAGE_HIGH_VOLUME_CONTENT7:", subject to availability.",
  PAGE_BOOKAPPOINTMENT:'Book your chat',
  PAGE_CLOSE_HEADING:'Our video banking hours are 8am to 8pm',
  PAGE_CLOSE_CONTENT:'Please come back then, or book in a meeting at a time that suits you.',
  PAGE_LEAVE_MEETING:'Close Window',
  PAGE_BROWSER_INCOMPATIBLE_HEADING:'Please use a different browser',
  PAGE_BROWSER_INCOMPATIBLE_CONTENT:"Unfortunately your current browser is not compatible with our real time video banking service at this time. If you would like to talk to a video banker straight away, please try again through Chrome, Edge or Firefox. Alternatively, you can book an appointment where you will receive guidance on a few simple steps to download an app and allow you to speak with our video banker at a time that suits.",
  PAGE_CANCEL_APPOINTMENT:'Cancel appointment',
  PAGE_GENERIC_REGRET_HEADING:"Video banking isn’t available right now" ,
  PAGE_GENERIC_REGRET_CONTENT1:"We’re making improvements so we can’t put you in touch with a video banker for the moment. Please try again later, or book in a video banking meeting at a time that suits you.",
  PAGE_GENERIC_REGRET_CONTENT2:"You can also talk to us on:",
  PAGE_GENERIC_REGRET_CONTENT3:"Royal Bank: 03457 242424",
  PAGE_GENERIC_REGRET_CONTENT4:"NatWest: 03457 888 444",
  PAGE_GENERIC_REGRET_CONTENT5:"Ulster Bank: 03457 424 365 (from the UK)",
  PAGE_GENERIC_REGRET_CONTENT:"We’re making improvements so we can’t put you in touch with a video banker for the moment. Please try again later, or book in a video banking meeting at a time that suits you.<br></br>You can also talk to us on:<ul style='list-style-type: square;color: rgb(66, 20, 95);margin-left:-18px'><li><span style='color:rgb(51,51,51)'>Royal Bank: 03457 242424</span></li><li><span style='color:rgb(51,51,51)'>NatWest: 03457 888 444</span></li><li><span style='color:rgb(51,51,51)'>Ulster Bank: 03457 424 365 (from the UK)</span></li></ul>",
  PAGE_GENERIC_REGRET_BOOKAPPOINTMENT:"Book an appointment",


  PRODUCT_LIST_LABEL: 'What do you want to talk to your video banker about?',
  PRODUCT_DEFAULT_VALUE: 'A Financial Health Check',
 
  

  EXISTING_CUSTOMER: [
    {
      "label": "Yes",
      "value": "Yes"
    }/* ,
    {
      "label": "No",
      "value": "No"
    } */
  ],


  TITLE_LIST: [
    { label: 'Please choose...', value: '' },
    { label: 'Mr', value: 'Mr' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Ms', value: 'Ms' },
    { label: 'Mx', value: 'Mx' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Others', value: 'Others' }
    ], 
  BUTTON_HELP_TEXT:'Next',
  PAGE_TITLE: 'About you',
  TITLE_LABEL: 'Title',
  TITLE_SELECT_PLACEHOLDER: 'Please choose...',
  FULLNAME_LABEL: 'Full name',
  LASTNAME_LABEL: 'Last Name:',
  POSTCODE_LABEL: 'Post code (optional)',
  POSTCODE_HELP_TEXT: "We'll only use this to identify you before we call",
  PHONENUMBER_LABEL: 'Phone number',
  PHONENUMBER_HELP_TEXT: "We’ll only use this to call you if you get cut off",
  
  EMAIL_ADDRESS_LABEL: 'Email:',
  EXISTING_CUSTOMER_LABEL: 'Are you an existing RBS customer?',
  NOTE_LABEL: 'Note',
  LETS_CHECK_TEXT: "Let's check your details first",
  PRIVACY_STMT_ONE:
    "We need this information to identify you before your video meeting. The information you tell us will be deleted within 90 days of your appointment, and it won't change your bank record. You can find our full privacy policy at ",
  PRIVACY_STMT_TWO:
    "Yes I’ve watched the video",
  PAGE_EXIT_BUTTON: 'Cancel',
  SUBMIT_BUTTON_TEXT: 'Submit',
  THANK_TO_CUSTOMER_HEADING: 'Exit',
  THANK_TO_CUSTOMER_NAME: 'Hello ,',
  THANK_TO_CUSTOMER_MESSAGE1:
    'Thank you for talking with us today. your video banking session with John ',
  THANK_TO_CUSTOMER_MESSAGE2:
    'is now complete. Hope you were satisfied with the out come.',
  THANK_TO_CUSTOMER_HAPPYBANKING: 'Happy Banking!',
  REGRET_TO_CUSTOMER_AGENT_UNAVAILABILITY_TITLE: "High Volume",
  REGRET_TO_CUSTOMER_AGENT_UNAVAILABILITY:
    'We are experiencing high volumes of video banking calls right now and hence request you to go through future booked video appointment or telephony or come back later',
  DISCLAIMER_TEXT: "We need this information to identify you before your video meeting. The information you tell us will be deleted within 90 days of your appointment, and it won’t change your bank record. You can find our full privacy policy at ",
CANCEL_BUTTON_TEXT:'Cancel',
NEXT_BUTTON_TEXT:'Next',
};

export const VALIDATION_MESSAGES = {
  PRIVACY_STMT_REQUIRED: 'Please select privacy statment',
  PRODUCT_SELECTION_REQUIRED:"Please select required product",
  EXISTING_CUSTOMER_REQUIRED:"Please select an option before you proceed",
  TITLE_REQUIRED: 'Please select a title',
  FIRST_NAME_REQUIRED: 'Please tell us your first name',
  LAST_NAME_REQUIRED: 'Please tell us your last name',
  FULL_NAME_REQUIRED: 'Please enter your name to proceed further',
  NAME_CHARS_RANGE: 'Full name should be between 2 to 40 characters',
  // NAME_INVALID_CHARS:
  //   "Sorry, we don't recognise your name. Please try again using letters A to Z. You can also use - and ' characters.",
  NAME_INVALID_CHARS:"The Value entered is not valid",
  CONTACT_NO_REQUIRED: 'Please enter your mobile/landline number',
  CONTACT_NO_INVALID:
    'The Value entered is not valid',
  EMAIL_REQUIRED: 'Please tell us your email address',
  EMAIL_MAX_LENGTH: 'Sorry, we can only accept up to 50 characters',
  EMAIL_INVALID: "Sorry, we don’t recognise this as a valid email address",
  EMAIL_COMMA:
    "Sorry, your email address won’t have a comma in it. Please check and try again.",
  EMAIL_AT_MISSING:
    'Sorry, your email address needs to have an @ in it, like name@host.com',
  EMAIL_SPACING:
    "Sorry, your email address won’t have any spaces. Please take them out.",
  NOTE_REQUIRED: 'Please write a notes  on your appointment',
  POSTAL_CODE_REQUIRED: 'Please tell us your postal code',
  POSTAL_CODE_INVALID: 'The Value entered is not valid'
};


export const PRODUCT_TILE_LIST = [{
  "id": 1,
  "label": "A Financial Health Check By Video",
  "imagePath": "fhc.png"
},
{
  "id": 2,
  "label": "Mortgage First Step By Video ",
  "imagePath": "mortgage.png"
},
{
  "id": 3,
  "label": "Apply For An OverDraft By Video",
  "imagePath": "overdraft.png"
},
{
  "id": 4,
  "label": "Apply for a Loan By Video",
  "imagePath": "loan.png"
},
{
  "id": 5,
  "label": "Apply for a Crediit Card By Video",
  "imagePath": "creditcard.png"
},
{
  "id": 6,
  "label": "Open A Current Account By Video",
  "imagePath": "currentac.png"
},
{
  "id": 7,
  "label": "Open A Savings Account By Video",
  "imagePath": "savingsac.png"
}
];



export const BRAND_CONST=
{
  "NWB" : {
      "name" : "NatWest",
      "theme" : "zb-natwest-nonpersonal-standard-theme",
      "cookiepolicylink" :"https://personal.natwest.com/global/cookie-privacy.html",
      "tilebgcolor" : "#42145f",
      "disclaimerlinklabel": "Natwest.com/privacy",
      "disclaimerlink": "Natwest.com/privacy",
      QUDINI_LINK:"https://rbs-bookings.qudini.com/booking-widget/storebooker/P82L628EGS2/153?",
  
      EXISTING_CUSTOMER_LABEL: 'Are you already a NatWest customer?', 
      PRODUCT_LIST: [
        {
          "label": "Financial Health Check",
          "value": "Financial Health Check"
        },
        {
          "label": "Other Service Query",
          "value": "Other Service Query"
        }
      ],
      
  PAGE_DISPLAY_VIDEO_TRANSCRIPT :"On screen title – Some important information we need to tell you <BR/> \
  <BR/> \
  <BR/> \
  Over the next few minutes, we want to share some important information. It’s about our commitment to you and our ability to serve you well.<BR/> \
  <BR/> \
  On screen title – 1. We’re here to help <BR/> \
  <BR/> \
  Firstly, some information about us. We’re here to help. We always give you the information you need, so you can make the best decisions. But we don’t give you any financial advice or recommendations. <BR/> \
  <BR/> \
  We are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.<BR/> \
  <BR/> \
  We have a lot of different products. Some of them have a monthly charge, while others are free. We’ll give you more information about these today. <BR/> \
  <BR/> \
  We’ll always do our best to help you. In person, online, by video or over the phone.<BR/> \
  <BR/> \
  But if you have a complaint, and you’re not happy with our answer, we’ll tell you how to raise it with the Financial Ombudsmen Service.<BR/> \
  <BR/> \
  On screen title – 2. Sharing your information<BR/> \
  <BR/> \
  Now we’ll tell you about how we use the information you give us.<BR/> \
  <BR/> \
  We sometimes need to share your information within our group. <BR/> \
  <BR/> \
  We share your information so we can:<BR/> \
  -	Protect you<BR/> \
  -	Check your identity<BR/> \
  -	Make sure you have the right products for your needs<BR/> \
  -	Prevent fraud and money laundering; and<BR/> \
  -	Help us comply with laws, manage our risk and protect our business<BR/> \
  <BR/> \
  We share your information with other companies in our group and our business partners, like the company that makes our debit and credit cards. <BR/> \
  <BR/> \
  We share your information with Credit reference agencies so we can do a few things like:<BR/> \
  •	a credit check – this can affect your credit score but we don’t do one if you’re just opening a savings account<BR/> \
  •	an identity check – this could be done on you and anyone else who’s linked to the account, but it doesn’t affect your credit score <BR/> \
  •	and to protect you and comply with laws – we must share information about how you manage your account, like payments made in, your balance, credit limits and any missed loan payments. They may also share this with other organisations.<BR/> \
  <BR/> \
  We share your information with fraud prevention agencies, and we also record any identified or suspected fraud, which could mean we, or others, may refuse to provide you with services, financing, or employment.<BR/> \
  <BR/> \
  We also share your information with industry regulators, government departments and similar organisations, like HM Revenue and Customs, who might in turn have to share it with other countries.<BR/> \
  <BR/> \
  Credit reference and fraud prevention agencies use your information because they have to prevent fraud and money laundering, to verify your identity, to protect you, their business, and to comply with laws.<BR/> \
  <BR/> \
  We’ll make decisions based on the automated checks of data we hold and receive about you.  You have rights in relation to these decisions, including a right to appeal if your application is declined. If it’s declined, we’ll usually keep your information for 5 years or longer if needed to comply with the law.<BR/> \
  <BR/> \
  If you want more details about how we use your information, or your rights, please ask for this before or at the start of your appointment or you can find our full privacy notice on the privacy page on our website<BR/> \
  <BR/> \
  <BR/> \
  On screen title – 3. Keeping your money safe<BR/> \
  <BR/> \
  Now let’s talk about how you are protected by the Financial Services Compensation Scheme. Your eligible deposits with us are protected up to the Financial Services Compensation Scheme limit, which is currently £85,000. The scheme makes sure you will get your money back in case the bank ever fails. It covers up to £85,000 in all the eligible accounts you have with us, and makes sure you'll get your money back within 15 working days. Any deposits you hold above the current limit are not covered.<BR/> \
  <BR/> \
  If you are opening a joint account, the £85,000 limit applies to both of you individually.<BR/> \
  <BR/> \
  We’ll send you more information about the scheme when you open your account and there’s a link to the FSCS website on our internet pages.<BR/> \
  <BR/> \
  My colleague will only summarise key information today and full details will be sent you by post or e-mail including a welcome letter and full terms and conditions. <BR/> \
  <BR/> \
  And finally, thanks for choosing NatWest.<BR/> \
  ",

  },
  "RBS": {
    "name" : "RBS",
    "theme" : "zb-rbs-nonpersonal-standard-theme",
    "cookiepolicylink" :"https://personal.rbs.co.uk/global/cookie-privacy.html",
    "tilebgcolor" : "#15366e",
    "disclaimerlinklabel": "Natwest.com/privacy",
      "disclaimerlink": "Natwest.com/privacy",
      QUDINI_LINK:"https://rbs-bookings.qudini.com/booking-widget/storebooker/P82L628EGS2/153?",
      EXISTING_CUSTOMER_LABEL: 'Are you already a Royal Bank of Scotland customer?', PRODUCT_LIST: [
        {
          "label": "Financial Health Check",
          "value": "Financial Health Check"
        },
        {
          "label": "Other Service Query",
          "value": "Other Service Query"
        }
      ],
    
  PAGE_DISPLAY_VIDEO_TRANSCRIPT :"On screen title – Some important information we need to tell you <BR/> \
  <BR/> \
  Over the next few minutes, we want to share some important information. It’s about our commitment to you and our ability to serve you well.<BR/> \
  <BR/> \
  On screen title – 1. We’re here to help <BR/> \
  <BR/> \
  Firstly, some information about us. We’re here to help. We always give you the information you need, so you can make the best decisions. But we don’t give you any financial advice or recommendations. <BR/> \
  <BR/> \
  We are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.<BR/> \
  <BR/> \
  We have a lot of different products. Some of them have a monthly charge, while others are free. We’ll give you more information about these today. <BR/> \
  <BR/> \
  We’ll always do our best to help you. In person, online, by video or over the phone.<BR/> \
  <BR/> \
  But if you have a complaint, and you’re not happy with our answer, we’ll tell you how to raise it with the Financial Ombudsmen Service.<BR/> \
  <BR/> \
  On screen title – 2. Sharing your information<BR/> \
  <BR/> \
  Now we’ll tell you about how we use the information you give us.<BR/> \
  <BR/> \
  We sometimes need to share your information within our group. <BR/> \
  <BR/> \
  We share your information so we can:<BR/> \
  -	Protect you<BR/> \
  -	Check your identity<BR/> \
  -	Make sure you have the right products for your needs<BR/> \
  -	Prevent fraud and money laundering; and<BR/> \
  -	Help us comply with laws, manage our risk and protect our business<BR/> \
  <BR/> \
  We share your information with other companies in our group and our business partners, like the company that makes our debit and credit cards. <BR/> \
  <BR/> \
  We share your information with Credit reference agencies so we can do a few things like:<BR/> \
  •	a credit check – this can affect your credit score but we don’t do one if you’re just opening a savings account<BR/> \
  •	an identity check – this could be done on you and anyone else who’s linked to the account, but it doesn’t affect your credit score <BR/> \
  •	and to protect you and comply with laws – we must share information about how you manage your account, like payments made in, your balance, credit limits and any missed loan payments. They may also share this with other organisations.<BR/> \
  <BR/> \
  We share your information with fraud prevention agencies, and we also record any identified or suspected fraud, which could mean we, or others, may refuse to provide you with services, financing, or employment.<BR/> \
  <BR/> \
  We also share your information with industry regulators, government departments and similar organisations, like HM Revenue and Customs, who might in turn have to share it with other countries.<BR/> \
  <BR/> \
  Credit reference and fraud prevention agencies use your information because they have to prevent fraud and money laundering, to verify your identity, to protect you, their business, and to comply with laws.<BR/> \
  <BR/> \
  We’ll make decisions based on the automated checks of data we hold and receive about you.  You have rights in relation to these decisions, including a right to appeal if your application is declined. If it’s declined, we’ll usually keep your information for 5 years or longer if needed to comply with the law.<BR/> \
  <BR/> \
  If you want more details about how we use your information, or your rights, please ask for this before or at the start of your appointment or you can find our full privacy notice on the privacy page on our website<BR/> \
  <BR/> \
  <BR/> \
  On screen title – 3. Keeping your money safe<BR/> \
  <BR/> \
  Now let’s talk about how you are protected by the Financial Services Compensation Scheme. Your eligible deposits with us are protected up to the Financial Services Compensation Scheme limit, which is currently £85,000. The scheme makes sure you will get your money back in case the bank ever fails. It covers up to £85,000 in all the eligible accounts you have with us, and makes sure you'll get your money back within 15 working days. Any deposits you hold above the current limit are not covered.<BR/> \
  <BR/> \
  If you are opening a joint account, the £85,000 limit applies to both of you individually.<BR/> \
  <BR/> \
  We’ll send you more information about the scheme when you open your account and there’s a link to the FSCS website on our internet pages.<BR/> \
  <BR/> \
  My colleague will only summarise key information today and full details will be sent you by post or e-mail including a welcome letter and full terms and conditions. <BR/> \
  <BR/> \
  And finally, thanks for choosing Royal Bank of Scotland.<BR/> \
  ",


}

  
};
export const REGRET_MESSAGES={
  err_01: 'Unfortunately, we are not able to connect you through to a Video Banker at this time as we do not yet support your Internet Browser.<br></br>Please use the latest version of one of the following browsers to access this service:<br></br><ul><li>Google Chrome</li><li>Microsoft Edge</li><li>Firefox</li></ul> <br></br>Alternatively, you can continue to book a Video Banking appointment by clicking&nbsp;<a href="https://rbs-bookings.qudini.com/booking-widget/storebooker/P82L628EGS2/153">here</a>',
  err_02: 'Unfortunately, our Video Bankers are currently handling a high volume of customer demand and are unable to assist you at this moment.<br></br>You can continue to book a Video Banking appointment from our Video Banker pages<br></br>',
  err_03: 'Unfortunately, we are unable to connect you to our Video Banking service right now.<br></br>You can also contact us over the phone on :<br></br>You can continue to book a Video Banking appointment from our Video Banker pages<br></br>RBS - Call us on: 03457 242424<br></br>NW - Call us on: 03457 888 444<br></br>UB - 0345 742 4365 if calling from the UK<br></br>'

};

export const ERROR_CODES={
  BROWSER_ERROR_CODE:"err_01",
  AGENT_UNAVAILABLE_ERROR_CODE:"err_02",
  SYSTEM_ERROR_CODE:"err_03",
  ZERO_ERROR_CODE:"00",
}
import React from 'react';
import ReactDOM from 'react-dom';
import { fromJS } from 'immutable';
import {MainHeading, SideImage, BookAppointment} from '../containers/commonUI'
import { COPY, VALIDATION_MESSAGES, PROD_LIST,ERROR_CODES, BRAND_CONST } from './landingtext';
import { withRouter } from 'react-router-dom';
import { NotificationLink } from '../containers/NotificationTemplate';
import * as CONFIG from './../../config.js';
import Spinner from './../../vb-components/vb-spinner/index';
import $ from 'jquery';
import { track_page } from '../../vb-mi';
import { browserName } from "react-device-detect";
import { getDeviceOS } from './../../common.js';

class WaitingRoom extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);

    $('#zmmtg-root').hide();
    window.vbZoomCall = this;    
    this.lambda = window.lambda;
    this.customerName ='';
    this.uuid='';
    this.startMeetingPoolInterval = 0;
    this.zoomMeetingData ={};
    this.zoomApp = true;
    
    this.state ={
      form: fromJS(this.props.location.state.form),
      brand : CONFIG.data.BRAND,
      heading : COPY.PAGE_WAITING_ROOM_HEADING ,
      mobileHeading: COPY.PAGE_WAITING_ROOM_HEADING_MOBILE,
      content1: COPY.PAGE_WAITING_ROOM_CONTENT1,
      content2: COPY.PAGE_WAITING_ROOM_CONTENT2,
      bottomLinkText: COPY.PAGE_BOOKAPPOINTMENT,
      bottomLink:CONFIG.data.BOOK_APPOINTMENT_URL,
      agentName:'',
      zURL:'',
      contactId:'',
      hiddenbtn:true,
      hiddenbtn2:true,
      hiddenbtn3:true,
      hiddencnt:false,
      hiddenlink:true,
      cnt2:'',
      meetInfo:null,
      appointObject:null,
      paddingtop:0,
      nstyle:{textDecoration:"none"},
      btnClick:false,
      join_meeting:false,
      meeting_started:false
    }
    
  }

  onSubmit()
  {

  }


  render() {
    let bookClick = () => {
      var os = getDeviceOS();
      //var appointMentObj = this;
      console.log("Inside bookClick() ");
      var pullParams = {
          FunctionName: CONFIG.data.CLOSE_CHAT_NAME,
          InvocationType: 'RequestResponse',
          LogType: 'None',
          Payload: JSON.stringify({
              "ConnectId" : this.state.contactId
          })
      };
      let delay = 0;
      if(os.isIOS)
      {
        delay = CONFIG.data.REDIR_TIMER;
      }

      async function stopchat(){
        await window.lambda.invoke(pullParams, function (err, data) {
          console.log("Inside closechat lambda invoke ");
            if (err) {
                //prompt(err);
                console.log('Exception Invoking CLOSE_CHAT Error' + err);
            } else {
              console.log("CLOSE_CHAT Req : " + JSON.stringify(pullParams));
              console.log("CLOSE_CHAT Resp : " + JSON.stringify(data));
            }
        });
        setTimeout(function (){window.open(CONFIG.data.BOOK_APPOINTMENT_URL, "_self");},delay); 
      };

      stopchat();
      
    };
    let zClick = () => {
      var c2 = this.state.cnt2;
      this.setState({hiddenbtn: true, content2:c2});
    };
    let zClick2 = () => {
      if(this.state.btnClick == false)
      {
        window.location.href = this.state.zURL;
        this.setState({hiddenbtn3: true, nstyle:{background:"#333333", textDecoration:"none"}, btnClick:true});
        this.startParticipantCountInterval(this.state.meetInfo.zoomMeetingID, this, true);
        //this.startParticipantCountTimer(CONFIG.data.PARTICIPANT_COUNT_TIMER,this.state.meetInfo,this.state.appointObject);
      }
      
      
    };
    let zClick3 = () => {
      this.setState({hiddenbtn2: true, nstyle:{background:"#333333",textDecoration:"none"}});
      this.startParticipantCountInterval(this.state.meetInfo.zoomMeetingID, this);
      //this.startParticipantCountTimer(CONFIG.data.PARTICIPANT_COUNT_TIMER,this.state.meetInfo,this.state.appointObject);
      const VBZoomCall = require('./vb-zoomcall').default;
      window.zoomMeetingStatus = "INPROGRESS";
      ReactDOM.render(
               <VBZoomCall meetingInfo={this.state.meetInfo} parentObj={this.state.appointObject} participantName={this.state.appointObject.customerName}></VBZoomCall>
               , document.getElementById('zoomCallid'));
    };
    return (
      <form id="waitingPage" >
          <div className="zb-container-vb">
            <div className='zb-column-is-12'>
                  <div className='zb-column-is-1'></div>
                  <div className='zb-column-is-8'>
                    <section>
                      
                      <div id="heading" >
                        <h1 className="vb-app-main-heading-l2">{this.state.heading}</h1>
                        <h1 className="vb-m-app-main-heading-l2">{this.state.mobileHeading}</h1>
                      </div>

                      <div className= 'vb-xl-waitspinner'>
                      <div className='vb-spinner vb-image-content2'>
                      <img src={'./static/360.gif'} alt = {"waiting_spinner"} title = {"waiting_spinner"}></img>
                      </div>
                  </div>

                      <div className= 'vb-l-waitspinner'>
                      <div className='vb-spinner vb-image-content2'>
                      <img src={'./static/360.gif'} alt = {"waiting_spinner"} title = {"waiting_spinner"}></img>
                      </div>
                  </div>
          <div className= 'vb-m-waitspinner'>
                      <div className='vb-spinner vb-image-content2 vb-image-content-padding1'>
                      <img src={'./static/360.gif'} alt = {"waiting_spinner"} title = {"waiting_spinner"}></img>
                      </div>
        </div>
                        <div id="waitingcnt" role="alert" className="vb-waiting-page-content">
                            {this.state.content1} <br/><br/>
                            {this.state.content2}
                        </div>
                    </section>
                  </div>  
            </div>          
        </div>
        <div className='zb-column-is-12' id="subheading" hidden = {this.state.hiddencnt}>
          <div className="zb-container-vb">
          <div className='zb-column-is-1'></div>
          <div className='zb-column-is-8'>
          <div  classname="vb-waiting-bottom-content" >
                          <h2 className="vb-app-sub-heading-l2">{COPY.PAGE_WAITING_ROOM_BOOK_SUBHEADING}</h2>
                          <div id="waitingcnt2" role="alert" className="vb-waiting-page-content-l2">
                          {COPY.PAGE_WAITING_ROOM_BOOK_CONTENT1} 
                          <b>{COPY.PAGE_WAITING_ROOM_BOOK_CONTENT2}</b>
                          {COPY.PAGE_WAITING_ROOM_BOOK_CONTENT3}
                          <b>{COPY.PAGE_WAITING_ROOM_BOOK_CONTENT4}</b>
                          {COPY.PAGE_WAITING_ROOM_BOOK_CONTENT5}
                          </div> <br/><br/>
                          <button type="button" onClick={bookClick} className="vb-waiting-button">{COPY.PAGE_WAITING_ROOM_BOOK_APPOINTMENT}</button>
                        </div>
            </div>
            </div>
        </div>

		<div className="zb-container-vb" hidden={this.state.hiddenlink}>
		  <nav className='zb-column-is-12'>
			<div className='zb-column-is-12'>
                  <div className='zb-column-is-1'></div>
                  <div className='zb-column-is-3'>
					<div className="vb-bottom-section3">                    
						<div className= "vb-next-button">
									  <a className="zb-button-primary" style={this.state.nstyle} href = {this.state.zURL} hidden = {this.state.hiddenbtn} role="button" title="Launch Zoom"  onClick={zClick}>Launch Zoom</a>
							        
									<button
										id="launchZoomApplication"
										value="Launch Zoom Application"
										type="button"
										className="zb-button-primary"
										title="Launch Zoom Application"
										style={this.state.nstyle}
										onClick={zClick2} hidden = {this.state.hiddenbtn2}>
										<div className="zb-next-text">Launch Zoom Application</div>
									</button>
						</div>
					</div>
				  </div>
				  <div className='zb-column-is-1'></div>
				  <div className='zb-column-is-3'>
					<div className="vb-bottom-section3">                    
						<div className= "vb-next-button">
						            <button
										id="launchZoomwithinBrowser"
										value="Launch Zoom within Browser"
										type="button"
										className="zb-button-primary"
										style={this.state.nstyle}
										title="Launch Zoom within Browser"
										onClick={zClick3} hidden = {this.state.hiddenbtn3}>
										<div className="zb-next-text">Launch Zoom within Browser</div>
									</button>
						</div>
					</div>
				  </div>
				  <div className='zb-column-is-4'></div>
		    </div>
		  </nav> 
		</div>
        <div id="zoomCallid">
        </div>  
        <div className="zb-container-vb" hidden = {this.state.hiddenlink}>
          <NotificationLink label={this.state.bottomLinkText} action={this.state.bottomLink} />                                     
        </div>

      </form > 
    );
  }

  componentDidMount()
  {
    const { form } = this.state;
    const pagetitle=COPY.WAITING_ROOM_PAGE_TITTLE;
    document.title=pagetitle;
    document.querySelector('.vb-app-main-heading-l2').focus();
    if(form.getIn(['vbfullName', 'value']) == undefined)
    {
      console.log("Customer Details Form  is not defined");
      this.props.history.replace({ pathname: '/' });
      return;

    }
    $('#heading').focus();
    track_page(pagetitle);
    //console.log('Inside vb-display:: Title=' + form.getIn(['firstName', 'value']));

    //alert("Form : "+this.state.form.getIn(['vbtitle', 'value']));
    //$('.waiting-3min').hide();
    $('#section-chat').hide();
    this.requestZoomMeeting();


      
  }

  requestZoomMeeting () {

    //e.preventDefault();
    const { form } = this.state;
    console.log("Myscript::SubmitDisplay Submit inside");

    let uuid = this.CreateUUID();
    this.customerName = this.state.form.getIn(['vbfullName', 'value']) ;
    this.uuid = uuid;
    var phoneNumber=this.state.form.getIn(['vbphoneNumber', 'value']);
    var postalCode=this.state.form.getIn(['vbpostalCode', 'value']);
    var businessFunction = (BRAND_CONST[this.state.brand]["name"]+" "+this.state.form.getIn(['vbprodlist', 'value']));
    if(phoneNumber === undefined || phoneNumber ===''){
      phoneNumber=null;
    }
    if(postalCode === undefined || postalCode===''){
      postalCode=null;
    }
   
   // sessionStorage.setItem("uuid", uuid);
  //  sessionStorage.setItem("customerName", customerName);
    this.startInterval(this.uuid, this);
    this.startAgentTimer(CONFIG.data.AGENT_TIMER1);

    this.startVBChatSession(
      {
          "pbrand"      : BRAND_CONST[this.state.brand]["name"],
          "customerName": this.customerName ,
          "transactionId": uuid,
          "business": businessFunction,
          "pnumber" : phoneNumber,
          "email" : null,//form.getIn(['email', 'value']),
          "ptype" : this.state.form.getIn(['vbprodlist', 'value']),
          "pcode" : postalCode,
          "note" : null,//form.getIn(['notes', 'value']),
          "rbs_account" : this.state.form.getIn(['vbexistingcust', 'value'])
      },
     (this.state.form.getIn(['vbtitle', 'value'])+" "+this.customerName)
    );
}

CreateUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

 getMeetingId(uuid)
{
  
  var params = {
    FunctionName: CONFIG.data.GET_MEETING_FLAG,
    InvocationType: 'RequestResponse',
    LogType: 'None',
    Payload: JSON.stringify({ "transactionId": uuid, "subAccount": this.state.brand })
  };
  return new Promise((resolve, reject) => {
    window.lambda.invoke(params, function (err, result) {
        if (err) {
            console.log("getMeetingId() error----"+err);
            resolve(err);
            
        } else {
                console.log("Get_Meeting Req : " + JSON.stringify(params));
                console.log("Get Meeting Resp : " + JSON.stringify(result));
            resolve(JSON.parse(result.Payload));        
        }
    });
  });

}

clearZoomTimer()
{
  console.log('clearZoomTimer Inside');
  console.log('clearing out the timer - '+this.timeout);
  clearTimeout(this.timeout);
  this.timeout= null;  
  console.log('clearning out the interval - '+this.startMeetingPoolInterval);
  clearInterval(this.startMeetingPoolInterval);
  this.startMeetingPoolInterval= null;
}

startAgentTimer(duration)
{
  var zoomCall = this;

  console.log('startAgentTimer Inside');

  zoomCall.timeout = setTimeout(function () {
    console.log('startAgentTimer time out');
      zoomCall.clearZoomTimer();
      if(zoomCall.state.agentName==="" &&(  zoomCall.zoomMeetingData.zoomMeetingID === undefined || zoomCall.zoomMeetingData.zoomMeetingID === null || zoomCall.zoomMeetingData.zoomMeetingID === '')){
        //$('.waiting-3min').show();
        //var padtop = window.getComputedStyle(document.getElementById('waitspinner')).getPropertyValue('padding-top');
        zoomCall.setState({heading : COPY.PAGE_WAITING_3MIN_HEADING, mobileHeading: COPY.PAGE_WAITING_3MIN_HEADING_MOBILE });
        var headHeight = $('.vb-app-main-heading-l2').outerHeight();
        $('.vb-l-waitspinner').css({'padding-top': headHeight+CONFIG.data.WAITING_PADDING2 });
        $('.vb-xl-waitspinner').css({'padding-top': headHeight+CONFIG.data.WAITING_PADDING });
      }
      else{
        window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;
        zoomCall.props.history.replace({ pathname: '/genericregret' });
      }

	zoomCall.timeout = setTimeout(function () {
		zoomCall.clearZoomTimer();
		if(zoomCall.state.agentName==="" &&(  zoomCall.zoomMeetingData.zoomMeetingID === undefined || zoomCall.zoomMeetingData.zoomMeetingID === null || zoomCall.zoomMeetingData.zoomMeetingID === '')){
      //$('.waiting-3min').hide();  
      window.errorCode =  ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE;
        zoomCall.props.history.replace({ pathname: '/highvolume' });
      }
      else{
        //$('.waiting-3min').hide();
        window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;
        zoomCall.props.history.replace({ pathname: '/genericregret' });
      }  
		
	}, CONFIG.data.AGENT_TIMER2);
  }, duration); // Alert the user in 60 sec that agent is not available
}



startInterval(uuid, zoomCall) {
  
  let data;
  console.log('startInterval Inside');
  zoomCall.startMeetingPoolInterval = setInterval(async function () {
  zoomCall.zoomMeetingData =  await zoomCall.getMeetingId(uuid);
    
      console.log(zoomCall.zoomMeetingData.startZoomFlag);
      if (zoomCall.zoomMeetingData.startZoomFlag) {
          zoomCall.clearZoomTimer();
          zoomCall.initiateZoomCall(zoomCall.zoomMeetingData,uuid);
      }
      if (uuid != null || uuid != undefined) {
        zoomCall.getMeetingId(uuid);
      }      
        
  }, CONFIG.data.POOLING_TIME_MEETING_FLAG);

  
}

initiateZoomCall(meetingInfo,uuid)
{
    //alert(' isIosDevice : ' + isIosDevice());
    this.setState({meeting_started:true});
    var appointmentObject = this;
    //var browserNameValue = "Safari";// browserName; // hardcode "Safari" for dev
    var os = getDeviceOS();
    //alert('Browser : ' + browserName + ', isIOS : ' + String(os.isIOS) + ', isMac : ' + String(os.isMac));
      console.log("Inside initZoomCall 1");
      // alert(' isIosDevice : ' + isIosDevice());
       var zoomURL = CONFIG.data.ZOOM_MAC_URL;
       if (os.isIOS || os.isAndroid) {
           zoomURL = CONFIG.data.ZOOM_IOS_URL;
       }
      // alert(' Sandbox Test is IOS: ' + os.isIOS + ' is Mac: ' + os.isMac + ' \n zoomURL: ' + zoomURL);
       window.onbeforeunload = null;
       try {
           console.log('initiateZoomCall() Inside, call will be open in zoom app');
           var fullURL = zoomURL + "?confno=" + meetingInfo.zoomMeetingID + "&uname=" + appointmentObject.customerName;
          //  if(browserName == 'Samsung Browser')
          //  {
          //    var ct2 = this.state.content2;
          //    var zcnt = ct2 + " Please click on the below button to Launch Zoom.";
          //    console.log('Zoom Android URL is '+fullURL);
          //    this.setState({hiddenbtn: false, zURL: fullURL, content2: zcnt, cnt2:ct2});
          //    this.startParticipantCountInterval(meetingInfo.zoomMeetingID, this, true);
          //    this.startParticipantCountTimer(CONFIG.data.PARTICIPANT_COUNT_TIMER, meetingInfo, uuid);
          //    //window.open(zoomURL);
          //  }
          //  else
          //  { if((os.isIOS || os.isMac) && browserName.indexOf('Safari') >= 0){
          //   window.location.href = fullURL; 
          //   this.startParticipantCountInterval(meetingInfo.zoomMeetingID, this, true);
          //   this.startParticipantCountTimer(CONFIG.data.PARTICIPANT_COUNT_TIMER, meetingInfo, uuid);
          //   }
          //   else
          //   {
              this.setState({hiddenbtn2: false, hiddenbtn3: false, zURL: fullURL, meetInfo: meetingInfo, appointObject:appointmentObject});
			  //this.startParticipantCountInterval(meetingInfo.zoomMeetingID, this);
			  this.startButtonTimer(CONFIG.data.PARTICIPANT_BUTTON_TIMER,meetingInfo,uuid);
          //   }
          //  }
           
          } catch (e) {
            console.log('Error! '+zoomURL);         
           }
          
          // zoomCall.props.history.replace({ pathname: '/exit' });
                 
  

    }

    getParticipantsDetails(meetingNumber) {
      
        var params = {
            FunctionName: CONFIG.data.GET_PARTICIPANTS_COUNT,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: JSON.stringify({ "meetingNumber": meetingNumber, "subAccount": this.state.brand })
        };
        return new Promise((resolve, reject) => {
            window.lambda.invoke(params, function (err, result) {
                if (err) {
                    console.log("getParticipantsDetails() error----" + err);
                    resolve(err);

                } else {
                  console.log("Get_Participant Req : " + JSON.stringify(params));
                  console.log("Get_Participant Resp : " + JSON.stringify(result));
                    resolve(JSON.parse(result.Payload));
                }
            });
        });

    }

    startParticipantCountTimer(duration, meetingInfo, uuid) {
      var zoomCall = this;

      console.log('startParticipantCountTimer Inside');
      var intTime = new Date().getTime();
      zoomCall.timeout = setTimeout(function () {
          console.log('startParticipantCountTimer time out');
		  
          var intNow = new Date().getTime();
          var ad = duration + 1000;
          var delay = 0;
          if (intNow - intTime > ad) 
          {
            delay = CONFIG.data.POOLING_TIME_PARTICIPANT_COUNT;
          }
          else
          {
            delay = 0;
          }
           setTimeout(function () {
			   console.log('inside the set timeout function for startParticipantCountTimer');
			   //console.log('*********************************************');
            zoomCall.clearZoomTimer();
            if(zoomCall.state.join_meeting === false)
            {
			  console.log('inside the if condition');
			  
              window.errorCode = ERROR_CODES.BROWSER_ERROR_CODE;
			  zoomCall.props.history.replace({ pathname: '/incompatible' });
			  var params = {
                FunctionName: CONFIG.data.PURGE_DATA,
                InvocationType: 'RequestResponse',
                LogType: 'None',
                Payload: JSON.stringify({
                    "transactionId": uuid,
                    "meetingNumber": meetingInfo.zoomMeetingID,
                    "subAccount": CONFIG.data.BRAND
                })
                };
                 
                window.lambda.invoke(params, async function (err, result) {
                console.log('PurgeLambda invoked');
                if (err) {
                console.log("PURGE_DATA Req : " + JSON.stringify(params));
                console.log('Invoke failed');
                    
                console.log(err);
                } else {
                    console.log("PURGE_DATA Req : " + JSON.stringify(params));
                    console.log("PURGE_DATA Resp : " + JSON.stringify(result));
                }
                });
                console.log('lambda finished');
              
            }
            
          },delay);
      }, duration); // Alert the user in 60 sec 
  }
  
	startButtonTimer(duration,meetingInfo,uuid){
		var zoomCall = this;

      console.log('startButtonTimer Inside');
      var intTime = new Date().getTime();
      zoomCall.timeout = setTimeout(function () {
          console.log('startButtonTimer time out');
		  
          var intNow = new Date().getTime();
          var ad = duration + 1000;
          var delay = 0;
          if (intNow - intTime > ad) 
          {
            delay = CONFIG.data.POOLING_TIME_PARTICIPANT_COUNT;
          }
          else
          {
            delay = 0;
          }
           setTimeout(function () {
			//console.log('**********************************');
			//console.log('Inside the nested setTimeout for function startButtonTimer');
            zoomCall.clearZoomTimer();
			//console.log('value of zoomCall.state.join_meeting - '+ zoomCall.state.join_meeting);
			
			
            if(zoomCall.state.join_meeting === false)
            {
				//console.log('******************************');
				//console.log('inside the if statement');
                var params = {
                FunctionName: CONFIG.data.PURGE_DATA,
                InvocationType: 'RequestResponse',
                LogType: 'None',
                Payload: JSON.stringify({
                    "transactionId": uuid,
                    "meetingNumber": meetingInfo.zoomMeetingID,
                    "subAccount": CONFIG.data.BRAND
                })
                };
                //try {
                //return new Promise(async resolve => {
                window.lambda.invoke(params, async function (err, result) {
                console.log('PurgeLambda invoked');
                if (err) {
                console.log("PURGE_DATA Req : " + JSON.stringify(params));
                console.log('Invoke failed');
                    //prompt(err);
                console.log(err);
                } else {
                    console.log("PURGE_DATA Req : " + JSON.stringify(params));
                    console.log("PURGE_DATA Resp : " + JSON.stringify(result));
                }
                });
                //});
                //}
                /*catch(e){
                console.log('Error caught!');
                console.log(e);
                console.log('Error finished');
                }*/
                console.log('lambda finished');
                //alert('Your session has expired. Please try again.');
                zoomCall.props.history.replace({ pathname: '/' });
                console.log('href changed');
            }
		        
          },delay);
          
      }, duration); // Alert the user in 90 sec 
		
	}

    startParticipantCountInterval(meetingNumber, zoomCall, zApp=false) {

        let data;
        console.log('startParticipantCountInterval Inside');
        zoomCall.startMeetingPoolInterval = setInterval(async function () {
            zoomCall.zoomMeetingData = await zoomCall.getParticipantsDetails(meetingNumber);

            console.log(zoomCall.zoomMeetingData.data);
             if (zoomCall.zoomMeetingData.data > 1) {
                 zoomCall.setState({join_meeting:true});
                 zoomCall.clearZoomTimer();
				 if(zApp)
				 {
                 zoomCall.props.history.push({ pathname: '/exit', state: {zApp: true} });
				 }
             } 
            if (zoomCall.zoomMeetingData.data <= 1) {
                zoomCall.getParticipantsDetails(meetingNumber);
            }

        }, CONFIG.data.POOLING_TIME_PARTICIPANT_COUNT);


    }

startVBChatSession(  bodyAttributes,  participantName){
  //sessionStorage.setItem("uuid", uuid);
  //sessionStorage.setItem("customerName", customerName);
  var appointMentObj = this;
  var pullParams = {
      FunctionName: CONFIG.data.INITIATE_CHAT_NAME,
      InvocationType: 'RequestResponse',
      LogType: 'None',
      Payload: JSON.stringify({
          "body": {
              "Attributes":bodyAttributes,
              "ParticipantDetails": {
                  "DisplayName": participantName
              },
              "ContactFlowId": "",
              "InstanceId": "" 
          },
          "subAccount": this.state.brand
      })
  };
  window.lambda.invoke(pullParams, function (err, data) {
      if (err) {
          //prompt(err);
          console.log('Exception Invoking INITIATE_CHAT_NAME Error' + err);
      } else {
        console.log("INITIATE_CHAT Req : " + JSON.stringify(pullParams));
        console.log("INITIATE_CHAT Resp : " + JSON.stringify(data));
          var pullResults = JSON.parse(data.Payload);
          //alert('pullResults : '+JSON.stringify(pullResults));
          var streamer = window.connect.ChatSession.create({
              chatDetails: pullResults.data.startChatResult,
              type: "CUSTOMER"
          });
          appointMentObj.setState({contactId : pullResults.data.startChatResult.ContactId});
          console.log("CONTACT-ID : " + pullResults.data.startChatResult.ContactId);
          appointMentObj.callStream(streamer);
      }
  });


}

createChatSession(chatDetails) {
    var streamer = window.connect.ChatSession.create({
        chatDetails: chatDetails.data.startChatResult,
        type: "CUSTOMER"
    });
    this.callStream(streamer);

}


titleCase(str) {
  str = str.split("@")[0].split(".")
  for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
}

callStream(session) {
  var appointmentObject = this;
    session.connect().then((response) => {
        return response;
    }, (error) => {
        console.log("Unsuccessful connection " + JSON.stringify(error));
        return Promise.reject(error);
    });

    session.onConnectionEstablished((data) => {
        console.log("Established!");
    })



    session.onMessage((message) => {
      //console.log("Received message: " + JSON.stringify(message));
      if (message.data.ParticipantRole == "AGENT") {
          let agentName = message.data.DisplayName;
          agentName = appointmentObject.titleCase(agentName);
          //$('.waiting-3min').hide();
          let tempName = agentName.split(" ");
          let agentDisplayName = tempName[0] + " " + (tempName.length > 1 ? tempName[1][0]: '')
         
          appointmentObject.setState({
            heading: COPY.PAGE_CALL_BEGIN_HEADING,
            mobileHeading: COPY.PAGE_CALL_BEGIN_HEADING,
            content1: COPY.PAGE_CALL_BEGIN_CONTENT1 +agentDisplayName+". "+(agentName.split(' ')[0])+COPY.PAGE_CALL_BEGIN_CONTENT2,
            content2: (agentName.split(' ')[0])+COPY.PAGE_CALL_BEGIN_CONTENT3,
            bottomLinkText: COPY.PAGE_CANCEL_APPOINTMENT,
            bottomLink:"#",
            agentName:agentDisplayName,
            hiddencnt:true,
            hiddenlink:false
          });
          $('.vb-l-waitspinner').css({'padding-top': CONFIG.data.WAITING_PADDING2 });
          $('.vb-xl-waitspinner').css({'padding-top': CONFIG.data.WAITING_PADDING });
          console.log('Agent accepted. Start Pooling for meeting');
          $('#heading').focus();
          appointmentObject.clearZoomTimer();
          appointmentObject.startInterval(appointmentObject.uuid, appointmentObject);
      }
  });


    session.onTyping((typingEvent) => {
        console.log("Received typing event: " + JSON.stringify(typingEvent));
    });

    session.onConnectionBroken((data) => {
        console.log("Connection broken.");
    });

    session.onEnded(() => {
      
      if(appointmentObject.state.agentName !="")
      {
        appointmentObject.clearZoomTimer();
        if(window.location.pathname === '/RHRNMeeting' && window.zoomMeetingStatus != 'INPROGRESS' && this.state.meeting_started === false)
        {
          window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;
          appointmentObject.props.history.replace({ pathname: '/genericregret' });             
        }
        window.zoomMeetingStatus = 'ENDED';  
      }
      
      console.log("chat has ended")
    });
}



}




export default WaitingRoom;


import React from 'react';
import ReactDOM from 'react-dom';
import { fromJS } from 'immutable';
import { withRouter } from 'react-router-dom';
import { RULES_MAP, VALIDATION_RULES } from './validationConstants';
import { RadioGroupField } from '@zambezi/formidable-components';
import { COPY, BRAND_CONST,VALIDATION_MESSAGES,ERROR_CODES } from './landingtext';
import PropTypes from 'prop-types';
import NavigationContainer from './vb-navigationContainer';
import PopupContainer from './vb-popupContainer';
import { MainHeading, SideImage } from './commonUI';
import * as CONFIG from "../../config.js";
import Validation from '../../vb-components/Validation/Validation';
import {track_page} from '../../vb-mi';
import $ from 'jquery';





class RHRNAppointment extends React.Component {
  constructor(props) {
    super(props);
    this.img_name = CONFIG.data.BRAND.toLowerCase() + '/step1.' + CONFIG.data.PIC_FORMAT;
    if(this.props.isValidationErr){
      if(this.props.errorCode === ERROR_CODES.BROWSER_ERROR_CODE){
        window.errorCode = ERROR_CODES.BROWSER_ERROR_CODE
        this.props.history.replace({ pathname: '/incompatible'});
      }
      else if(this.props.errorCode === ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE){
        window.errorCode =  ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE;
        this.props.history.replace({ pathname: '/highvolume'});
      }
      else{
        window.errorCode =  ERROR_CODES.SYSTEM_ERROR_CODE;
        this.props.history.replace({ pathname: '/genericregret'});
      }
    
    }

    
    var prodDefaultValue = BRAND_CONST[this.props.brand].PRODUCT_LIST[0].value ;
    this.state = {
      stepNo : 1, 
     // existingCustomer : true,
     zURL : 'https://www.google.com/',
     isDivShowing : true,
     brand : this.props.brand,
     form: fromJS({
      vbprodlist: {
        value: prodDefaultValue,
        message: undefined,
        touched: false,
        rendered: true
      },
      vbtitle: {
        value: undefined,
        message: null,
        touched: false,
        rendered: true
      },
      vbfullName: {
        value: undefined,
        message: undefined,
        touched: false,
        rendered: true
      },
      vbpostalCode: {
        value: undefined,
        message: undefined,
        touched: false,
        rendered: false
      },
      vbphoneNumber: {
        value: undefined,
        message: undefined,
        touched: false,
        rendered: true
      },
      vbexistingcust: {
        value: undefined,
        message: undefined,
        touched: false,
        rendered: false
      },
      vbprst: {
        value: undefined,
        message: undefined,
        touched: false,
        rendered: false
      }
    })
    };


    //this.existingCustomer = true;
    this.onChange = this.onChange.bind(this);
   // this.onBlur = this.onBlur.bind(this);
   // this.onClick = this.onClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount(){
    const pagetitle=COPY.PRODUCTION_PAGE_TITLE;
    //document.title=pagetitle;
	if(this.props.errorCode !== ERROR_CODES.BROWSER_ERROR_CODE && this.props.errorCode !== ERROR_CODES.AGENT_UNAVAILABLE_ERROR_CODE
      && window.errorCode !==  ERROR_CODES.SYSTEM_ERROR_CODE){
      track_page(pagetitle);
      }
     
  }

  radioButtonFocus = (e,v) => {
    console.log('radio button focused value: '+v);
    $("input[name='prodType'][value='"+v+"']").parent().addClass('zb-input-label-name vb-radiobutton-focus');
  }

  radioButtonBlur = (e) => {
    console.log('radio button blur');
    $("input[name='prodType']").parent().removeClass().addClass('zb-input-label-name');
    //$("input[name='prodType']:checked").parent().addClass('zb-input-label-name vb-radiobutton-focus');
  }
  
  onChange(name,value) {
    const { form } = this.state;
    this.setState({
      form: form.setIn(['vbprodlist', 'value'], value)
    });
  }
  onSubmit(e) {
    let { form } = this.state;
  //  form = this.validate(form);
   form= this.validateField("vbprodlist", form);
    this.setState({ form });
  // console.log("message"+ )
   const val=form.getIn(['vbprodlist', 'message']);
    if(val == undefined ){
      return true;
    }
    $('input[name=vbprodlist]').attr('aria-describedby','errorDescription-vbprodlist');
    return false;
  }
  isValid(id) {
    const { form } = this.state;
    //console.log('errormessage valid' + form.getIn([id, 'message']));
    return !form.getIn([id, 'message']);
}

validateField(id, form) {
  let message, rules, validation, value, rendered;
  rules = RULES_MAP[id];
  value = form.getIn([id, 'value']);
  rendered = form.getIn([id, 'rendered']);
  if (rendered) {
      validation = rules
          .map(id => VALIDATION_RULES()[id])
          .find(rule => rule.test(value));
      message = validation
          ? VALIDATION_MESSAGES[validation.messageCopyKey]
          : undefined;
  }
  console.log("id",id);
  console.log("message",message);
  return form
      .setIn([id, 'message'], message)
      .setIn([id, 'miKey'], 'test')
      .setIn([id, 'touched'], true);
}


  render() {
    const { form } = this.state;

      return (

          <form id="appointmentForm" > 
              <div><PopupContainer /></div>
            <div className="zb-container-vb">
               
          <div className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className='zb-column-is-8'>
                  <MainHeading firstLine= {COPY.PRODUCTION_SELECTION_TITLE} secondLine = {COPY.PAGE_STEP_1_OF_3_HEADING}/>
                  <section >
                    <div className = "vb-app-content">
                      <label>{COPY.PRODUCT_LIST_LABEL}</label>
                    </div>
                    
                    <RadioGroupField className = "vb-app-content2"
                      name="prodType"
                      value={BRAND_CONST[this.props.brand].PRODUCT_LIST[0].value} 
                      values={BRAND_CONST[this.props.brand].PRODUCT_LIST} 
                      onChange={this.onChange}
                      onFocus={this.radioButtonFocus}
                      onBlur={this.radioButtonBlur}
                      aria-describedBy="errorDescription-prodType"
                    />
                    
                    {!this.isValid('vbprodlist') && (
                                    <Validation>
                                        {[form.getIn(['vbprodlist', 'message']),"errorDescription-prodType"]}
                                    </Validation>
                                )}
                  </section>
                </div>
                <div className='zb-column-is-3'>
                    <SideImage alt={"sp_book"} title = {"sp_book"} imgName= {this.img_name}/>
                </div>
          </div>          
        </div> 

        <div className="zb-container-vb">
            <NavigationContainer action="/aboutYou" formdata={this.state.form} parentObject={this} />                     
        </div> 
      </form >
    );
  }
}

RHRNAppointment.displayName = 'Video Banking RHRN Appointment';
RHRNAppointment.propTypes = {
  titles: PropTypes.array,
  onChange: PropTypes.func
};

export default withRouter(RHRNAppointment);
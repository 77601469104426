import React, { useState, useEffect } from "react";
import {initVBSession,isAgentAvailable, initOnAppExit, getSsmParameters} from './../src/common';
import RHRNAppointment from './pages/containers/Vb-landing';
import CustomerDetails from './pages/containers/vb-input-component';

//import VBZoomCall from './pages/containers/vb-zoomcall';
import Header from './header';
import Footer from './footer';
import ErrorBoundary from './ErrorBoundary';
import $ from 'jquery';
import {BRAND_CONST, ERROR_CODES} from './pages/containers/landingtext';
import * as CONFIG from "./../src/config.js";
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import DisplayVideo from './pages/containers/vb-dispay-video';
import CallWaitingRoom from './pages/containers/vb-waiting-room';
import { browserName, browserVersion } from "react-device-detect";
import ExitAppointment from './pages/containers/vb-exit-appointment'
import HighVolume from './pages/containers/vb-high-volume';
import IncompatibleBrowser from './pages/containers/vb-browser-incompatible';
import GenericRegret from './pages/containers/vb-generic-regret';
import ScrollToTop from './pages/containers/vb-scrolltop';

import {track_page} from './vb-mi';
import Spinner from './vb-components/vb-spinner/index';
//import store from './store';
//import { SetBrand } from "./action";

function App() {
  const [statusData, setstatus] = useState({
    status: 'loaded',
    isValidationErr : false,
    isProductPageRequired: false,
    errorCode : ERROR_CODES.ZERO_ERROR_CODE
  });
  //const netStatus = true;
    const [netStatus, setnetStatus] = useState(() => {
        if (navigator.onLine) {
          return true;
        } else {
          return false;
        }
      });
      useEffect(() => {
        window.ononline = (e) => {
          setnetStatus(true);
        };
        window.onoffline = (e) => {
          setnetStatus(false);
        };

        document.querySelector('body').setAttribute('class',  BRAND_CONST[CONFIG.data.BRAND].theme + ' vb-main-body');

      }, [netStatus]);

    //track_page("Home");
    initOnAppExit();

    //console.log('app.js + :: brand=' + BRAND_CONST[CONFIG.data.BRAND].theme);
    // localStorage.setItem('vbbrand', BRAND_CONST[CONFIG.data.BRAND].theme);
    
    initVBSession(CONFIG.data);
    console.log('BrowserName : ' + browserName + ' check : ' + (browserName == 'Chrome'));
    
    var isValidBrowser = false;
    if (browserName == 'Chrome' || browserName == 'Firefox' || browserName == 'Edge' || browserName == 'Samsung Browser' || browserName.indexOf('Safari') >= 0) { isValidBrowser = true }
    
    var isValidationErr=false;
    var errorCode=ERROR_CODES.ZERO_ERROR_CODE;
    
    var isProductPageIsRequired = true;
    
    useEffect(() => {
      var getSsmParametersPromise = getSsmParameters(CONFIG.data);
      getSsmParametersPromise.then(response => {
        
        console.log('index.js getSsmParametersPromise=' + response.Payload );
        isProductPageIsRequired= JSON.parse(response.Payload).isProductPageRequired;
        //isProductPageIsRequired = true;
        if(!isValidBrowser){
          isValidationErr=true;
          errorCode=ERROR_CODES.BROWSER_ERROR_CODE;
        }
        window.errorCode= errorCode;
        window.isProductPageIsRequired = isProductPageIsRequired;
        setstatus({
            status: 'loaded',
            isValidationErr : isValidationErr,
            isProductPageRequired: isProductPageIsRequired,
            errorCode : errorCode
        });
      });
    }, [statusData.status, statusData.isValidationErr, statusData.isProductPageRequired , statusData.errorCode]);
      
   

  
    $("#zmmtg-root").hide();
    
    if(statusData.status == "loading")
    if(netStatus){
      return (
        <div className='spinner-vertical-align'>
        <div className= 'vb-m-spinner'>
        <div className="zb-container-vb">
          <div className='zb-column-is-12'>
              <div className='vb-spinner-container'>
                  <div className='vb-spinner vb-image-content vb-image-content-padding1'>
                    <Spinner tabIndex="-1" />
                  </div>
              </div>
          </div>
        </div>
      </div>
      
      <div className='zb-column-is-3' tabIndex="-1" >                    
        <div className='vb-spinner-container vb-l-spinner' tabIndex="-1">
          <div className='vb-spinner vb-image-content vb-image-content-padding' tabIndex="-1">
            <Spinner tabIndex="-1" />
          </div>
        </div>
      </div>
      </div>
      )
    }else{
      return (
        <div>
        <Header brand = {CONFIG.data.BRAND} />
            <div className="zb-container-vb">
               
               <div className='zb-column-is-12'>
                     <div className='zb-column-is-1'></div>
                     <div className='zb-column-is-8'>
                       <h className="vb-app-main-heading">Internet is disconnected. Please check your connection and try again.</h>
                       </div>
                       </div>
                       </div>
        </div>
      )
    }

    if(statusData.status == "loaded" && statusData.isProductPageRequired == true)
    if(netStatus){
    return (
    
     <main className='vb-app-container'>
     <ErrorBoundary brand = {CONFIG.data.BRAND} >
     
     <Header brand = {CONFIG.data.BRAND} />
            
            <BrowserRouter >
              <ScrollToTop/>
              <Switch>
                <Route
                  exact
                  path="/" 
                 /* component={
                    this.state.isValidationErr ? CustomerRegret : Landing 
                  }*/
                  
                  render={props =>  <RHRNAppointment {...props} isValidationErr={statusData.isValidationErr} errorCode= {statusData.errorCode} isProductPageIsRequired={statusData.isProductPageRequired} brand={CONFIG.data.BRAND} />  }
                />

               

                <Route
                  exact
                  path="/Vb-rbs"
                  render={props => <RHRNAppointment {...props} brand={CONFIG.data.BRAND}  />}
                />
                <Route
                  exact
                  path="/Vb-nwb"
                  render={props => <RHRNAppointment {...props} brand={CONFIG.data.BRAND}  />}
                />
		            <Route
                  exact
                  path="/Vb-ubn"
                  render={props => <RHRNAppointment {...props} brand={CONFIG.data.BRAND}  />}
                />
                <Route exact path="/aboutYou" component={CustomerDetails}  />                
                <Route exact path="/displayVideo" component={DisplayVideo} />
                <Route exact path="/RHRNMeeting" component={CallWaitingRoom} />
               
               <Route exact path="/exit" component={ExitAppointment} />
                <Route exact path="/highvolume" component={HighVolume} />
                <Route exact path="/incompatible" component={IncompatibleBrowser} />
                <Route exact path="/genericregret" component={GenericRegret} />

                <Route path="*" render={props =>  <RHRNAppointment {...props} isValidationErr={statusData.isValidationErr} isProductPageIsRequired={statusData.isProductPageRequired}  errorCode= {statusData.errorCode} brand={CONFIG.data.BRAND} />  }/>
              </Switch>
            </BrowserRouter>
            <Footer />
        </ErrorBoundary>
        </main>
        )
        }else{
          return (<main className='vb-app-container'>
            <Header brand = {CONFIG.data.BRAND} />
            <br/>
            <div className="zb-container-vb">
               
               <div className='zb-column-is-12'>
                     <div className='zb-column-is-1'></div>
                     <div className='zb-column-is-8'>
                       <h className="vb-app-main-heading">Internet is disconnected. Please check your connection and try again.</h>
                       </div>
                       </div>
                       </div>
      </main>
          )
        }
  
  if(statusData.status == "loaded" &&  statusData.isProductPageRequired == false)
  if(netStatus){
    return (
     <main className='vb-app-container'>
     <ErrorBoundary brand = {CONFIG.data.BRAND} >
     <Header brand = {CONFIG.data.BRAND} />
            <BrowserRouter >
              <ScrollToTop/>
              <Switch>
                <Route
                  exact
                  path="/" 
                  render={props =>  <CustomerDetails {...props} isValidationErr={statusData.isValidationErr} errorCode= {statusData.errorCode} isProductPageIsRequired={statusData.isProductPageRequired}  brand={CONFIG.data.BRAND} />  }
                />
                <Route
                  exact
                  path="/Vb-rbs"
                  render={props => <RHRNAppointment {...props} brand={CONFIG.data.BRAND}  />}
                />
                <Route
                  exact
                  path="/Vb-nwb"
                  render={props => <RHRNAppointment {...props} brand={CONFIG.data.BRAND}  />}
                />
		            <Route
                  exact
                  path="/Vb-ubn"
                  render={props => <RHRNAppointment {...props} brand={CONFIG.data.BRAND}  />}
                />
                <Route exact path="/aboutYou" component={CustomerDetails}  />                
                <Route exact path="/displayVideo" component={DisplayVideo} />
                <Route exact path="/RHRNMeeting" component={CallWaitingRoom} />
               <Route exact path="/exit" component={ExitAppointment} />
                <Route exact path="/highvolume" component={HighVolume} />
                <Route exact path="/incompatible" component={IncompatibleBrowser} />
                <Route exact path="/genericregret" component={GenericRegret} />
                <Route path="*" render={props =>  <CustomerDetails {...props} isValidationErr={statusData.isValidationErr} isProductPageIsRequired={statusData.isProductPageRequired}  errorCode= {statusData.errorCode} brand={CONFIG.data.BRAND} />  }/>
              </Switch>
            </BrowserRouter>
            
            
            
            <Footer />
           
            
        </ErrorBoundary>
        </main>
        )
        }else{
            return (
                <main className='vb-app-container'>
                    <Header brand = {CONFIG.data.BRAND} />
                    <br/>
                    <div className="zb-container-vb">
                        <div className='zb-column-is-12'>
                            <div className='zb-column-is-1'></div>
                            <div className='zb-column-is-8'>
                                <h className="vb-app-main-heading">Internet is disconnected. Please check your connection and try again.</h>
                            </div>
                        </div>
                    </div>
                </main>

                );
      }


  
}



export default App
/*<Route exact path="/zoom" component={ZoomCall} />} />*/
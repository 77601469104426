

export function track_page(pagename)
{
    console.log('MI track page:' + pagename);
    window.digitalData["page"]["url"]=window.location.href;
    window.digitalData["page"]["name"]=pagename;
    var newEvent = {
        "target": "PAGE",
        "phase": "LOAD",
        "track": true,
        "timeStamp": Date.now()
        }
    window.digitalData.events.push(newEvent);
    
} 


 
 

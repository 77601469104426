import React from 'react';
import { COPY,BRAND_CONST, VALIDATION_MESSAGES, PROD_LIST } from './landingtext';
import { withRouter } from 'react-router-dom';
import * as CONFIG from "./../../config";

class MainHeading extends React.Component {
  constructor(props) {
    super(props);
    this.firstLine = props.firstLine;
    this.secondLine = props.secondLine;
  }
  
  componentDidMount(){
    
    document.activeElement.blur();
    document.querySelector('h1').focus();
  }
  render()
  {
    return (
      <section>
          <h1  className="vb-app-main-heading">{this.firstLine}</h1>
          <h3  className="vb-app-sub-heading">{this.secondLine}</h3>
      </section>            
    );
  }

}


class SideImage extends React.Component {
    constructor(props) {
      super(props);
      this.imgName = props.imgName;
    }
    
    render()
    {
      return (
        <div className="vb-image-content vb-image-content-padding vb-atom-image-showhide">
            <div className= "vb-atom-image vb-atom-image-showhide">
              <img src={'./static/' + this.imgName} alt = {this.props.alt} title = {this.props.title} className="vb-atom-image vb-atom-image-showhide"></img>
            </div>
        </div>              
      );
    }
  
  }


  class BookAppointment extends React.Component {
    constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
      this.props= props;

    }
    onSubmit(event) {
      

  }
    render()
    {
      return (
            <div className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className ="zb-column-is-11">
                <div className="vb-bottom-section2">
                  <div className="vb-book-appointment">                    
                    <a href={CONFIG.data.BOOK_APPOINTMENT_URL} role="button" title={this.props.content} className="zb-cancel-primary">{this.props.content}</a>
                  </div>
                </div>                  
                </div>
            </div>                      
      );
    }
  
  }






export  {MainHeading, SideImage, BookAppointment};
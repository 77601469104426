import React from 'react';
import { COPY } from './pages/containers/landingtext';
import Header from './header';
import Footer from './footer';
import ReactDOM from 'react-dom';
import { fromJS } from 'immutable';
import {MainHeading, SideImage, BookAppointment} from './pages/containers/commonUI';
import * as CONFIG from './config';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = { hasError: false };
    this.spath = './static/' + CONFIG.data.BRAND.toLowerCase() + '/generic_regret.' + CONFIG.data.PIC_FORMAT;
  }
  onSubmit(e) {
    let { form } = this.state;
    let errorMessageFlag = true;

    this.props.history.push({ pathname: '/', state: { form: form } });

  }
  componentDidMount(){
   // const pagetitle=COPY.EXIST_PAGE_TITLE;
   // document.title=pagetitle;
    
    //document.querySelector('.vb-app-main-heading-l2').focus();
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return( 
        <form id="generic-regret-page" > 
        <Header brand = {CONFIG.data.BRAND} />      
        <div className= 'zb-is-visible-mobile-block zb-is-visible-tablet-block vb-m-spinner'>
          <div className="zb-container-vb">            
            <div className='zb-column-is-12'>
                <div className='vb-spinner-container'>
                    <div className='vb-spinner'>
                        <div className="vb-image-content vb-image-content-padding1">
                          <div className= "vb-atom-image">
                            <img src={this.spath} alt="generic_regret" title="generic_regret" className="vb-atom-image"></img>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>      
  
          <div className="zb-container-vb">
              <div className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className='zb-column-is-8'>
                  <section>
                    <h1 className="zb-is-hidden-mobile vb-app-main-heading-l2">{COPY.PAGE_GENERIC_REGRET_HEADING}</h1>
                    <h1 className="zb-is-visible-mobile-block vb-m-app-main-heading-l2">{COPY.PAGE_GENERIC_REGRET_HEADING}</h1>
                    
                    <div  className="vb-waiting-page-content">
                      {COPY.PAGE_GENERIC_REGRET_CONTENT1}<br></br>
                        {COPY.PAGE_GENERIC_REGRET_CONTENT2}
                        <ul>
                            <li>{COPY.PAGE_GENERIC_REGRET_CONTENT3}</li>
                            <li>{COPY.PAGE_GENERIC_REGRET_CONTENT4}</li>
                            <li>{COPY.PAGE_GENERIC_REGRET_CONTENT5}</li>
                        </ul>
                    </div>
                  </section>                               
                </div>
                <div className='zb-column-is-3' tabIndex="-1" >                    
                  <div className='zb-is-hidden-mobile vb-spinner-container vb-l-spinner' tabIndex="-1">
                    <div className='vb-spinner' tabIndex="-1">                        
                        <div className="vb-image-content vb-image-content-padding1">
                          <div className= "vb-atom-image">
                            <img src={this.spath} alt="generic_regret" title="generic_regret" className="vb-atom-image"></img>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
               
          </div>
          <div className="zb-container-vb">
                <BookAppointment content={COPY.PAGE_BOOKAPPOINTMENT}/>                                         
          </div>
          {/* <Footer /> */}
        </form >
      );
    }
    return children;
  }
}

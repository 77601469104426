import React from 'react';
import '../vb-spinner/spinner.css';
class Spinner extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let bars = [];
        const props = this.props;

        for (let i = 0; i < 16; i++) {
            let barStyle = "react-spinner_bar spinner-rotate-"+i;
           /* barStyle.WebkitAnimationDelay = barStyle.animationDelay =
                (i - 16) / 10 + 's';

            barStyle.WebkitTransform = barStyle.transform =
                'rotate(' + (i * 22.5) + 'deg) translate(146%)';
            */
            bars.push(
              <div className={barStyle} key={i} />
            );
        }

        return (
            <div {...props} className={(props.className || '') + ' react-spinner'}>
                {bars}
            </div>
        );
    }
};

export default Spinner;

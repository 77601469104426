import React from 'react';
import { COPY, VALIDATION_MESSAGES, PRODUCT_TILE_LIST } from './landingtext';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { browserName, isFirefox } from "react-device-detect";
import { getDeviceOS } from './../../common.js';
class PopupContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.title='Video Banking';
		//alert('isIosDevice : ' + isIosDevice())  && browserName.indexOf('Safari') >= 0; 
		//let userAgentString = navigator.userAgent;
		//let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
		var os = getDeviceOS();
		console.log("Device used by user - "+JSON.stringify(os));
		//alert("Device used by user - "+JSON.stringify(os)+" Browser Name : "+browserName);
		//var isIOS=Boolean("true");
		//browserName='Safari';
		// if(((os.isIOS || os.isMac) && browserName.indexOf('Safari') >= 0 )){
		// 	$('.in-browser').hide();
		// 	$('.no-browser').hide();
		// 	$('.zb-container-vb').hide();
		// 	$('.vb-footer-line').hide();
		// 	$('.vb-footer-content').hide();
		// 	$("#myModal").css("display", "block");
		// 	$('.zb-container-vb').first().show();
		// 	$('.browser').html("Yes – Let's Video Chat");
		// 	$('#priviousTable').hide();
		// 	$('.modal-text-container').addClass('safari-modal-text-container');
		// 	$('.modal-text-container').removeClass('modal-text-container');
		// 	$('.modal-button').addClass('safari-popup-modal-button');
		// 	$('.modal-button').removeClass('modal-button');
		// }
		// else if (browserName == 'Samsung Browser'){
			
		// 	$('.in-browser').hide();
		// 	$('.safari-browser').hide();
		// 	$('.zb-container-vb').hide();
		// 	$('.vb-footer-line').hide();
		// 	$('.vb-footer-content').hide();
		// 	$("#myModal").css("display", "block");
		// 	$('.zb-container-vb').first().show();
		// 	$('.browser').html('Continue - I have Zoom installed');
			
		// }
		// else {
			$('.no-browser').hide();
			$('.safari-browser').hide();
			$('.zb-container-vb').hide();
			$('.vb-footer-line').hide();
			$('.vb-footer-content').hide();
			$("#myModal").css("display", "block");
			$('.zb-container-vb').first().show();
			
			
		// }
		// //alert('Browser : ' + userAgentString + ', firefoxAgent : ' + firefoxAgent + ', isFirefox : ' + isFirefox + ', isIOS : ' + String(os.isIOS) + ', isMac : ' + String(os.isMac));
		// if (true) {
		// 	//
		// 	//$('.vb-header').hide();
		// 	$('.zb-container-vb').hide();
		// 	$('.vb-footer-line').hide();
		// 	$('.vb-footer-content').hide();
		// 	$("#myModal").css("display", "block");
		// 	$('.zb-container-vb').first().show();

		// }
	}

	proceedToVideoBanking() {
		$("#myModal").css("display", "none");
		$('.vb-header').show();
		$('.zb-container-vb').show();
		$('.vb-footer-line').show();
		$('.vb-footer-content').show();
	}

	
	openZoomDownloadLink() {
		window.open("https://zoom.us/download");
	}

	closeWindow() {
		window.close();
	}
	
	showHideBrowserList(){
		var divValue = $.trim($('#collapseDiv').text());
		var isHidden = (divValue=='+');
		if(isHidden)
		{
			$('.safari-browser-child').show();
			$('#collapseDiv').text('- ');
		}
		else
		{
			$('.safari-browser-child').hide();
			$('#collapseDiv').text('+ ');
		}
		
	}

	render() {
		return (
			<div id="myModal" className="modal">

				<div className="modal-content">
				<div className="modal-header">Important information for Users</div>
					<p></p>
					<div className="modal-text-container">
						
						<div className="in-browser">
							<p className="primary-instructions">To get the best experience from our Video Banking service you may wish to use your Zoom app, if you have it installed on your device.</p>
							<p className="primary-instructions">However you can also continue within the browser for your Zoom session, if you do not wish to use the Zoom app.</p>
						</div>
						<div className="no-browser">
							<p className="primary-instructions">To get the best experience from our Video Banking service, you need to have the Zoom app installed on your device.</p>
						</div>
						<div className="safari-browser">
							<p className="primary-instructions safari-popup-text-align">Safari Browser not supported 
							</p>
							<p className="primary-instructions safari-popup-text-align">
								Please Download Zoom to get the best experience during your Video Banking call
							</p>
						</div>
						<div>

							<table className="modal-table">
								<tbody>
									<tr>
										<td>
											<div className="vb-next-button">
												<button
													id="continue"
													value=""
													type="button"
													className="modal-button"
													onClick={this.proceedToVideoBanking}>
													<div className="zb-next-text browser">Continue</div>
												</button>
											</div>
										</td>

									</tr>
								</tbody>
							</table>
						</div>
						
						<div className="no-browser">
							<p className="secondary-instructions">If you do not have Zoom installed and wish to continue, please install Zoom.</p>
							<p className="secondary-instructions">Alternatively, you may use Chrome, Edge or Firefox as a browser without installing Zoom.</p>
						</div>
						<div className="safari-browser">
							<p className="primary-instructions safari-popup-text-align safari-popup-text-bold" onClick={this.showHideBrowserList}>
								<label id="collapseDiv" > + </label> 
								 No access to zoom?
							</p>
						</div>
						<div className="safari-browser-child safari-popup-hidden">
							
							<p className="primary-instructions safari-popup-text-align">Don’t worry. Just come back to this page using one of the browsers below instead</p>
							
							<p className="primary-instructions safari-popup-text-align"> 
								<img src={'./static/BrowserList.png'} alt = {'Browsers'} title = {'Browsers'} ></img>
							</p>
						</div>

						
						<table className="modal-table" id="priviousTable">
							<tbody>
								<tr>

									<td>
										<div className="vb-next-button">
											<button
												id="previous"
												value=""
												type="button"
												className="modal-button"
												onClick={this.closeWindow} >
												<div className="zb-next-text">Previous</div>
											</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
						

				</div>

			</div>
		);
	}
}
export default withRouter(PopupContainer);
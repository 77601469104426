import React from 'react';
import { COPY, VALIDATION_MESSAGES, PRODUCT_TILE_LIST } from './landingtext';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
class NavigationContainer extends React.Component {
    constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);
    }
    onSubmit(event) {
        console.log("formdata",this.props.formdata);
        if(!(this.props.parentObject === undefined)){
          if( this.props.parentObject.onSubmit(event))
          {
            this.props.history.replace({ pathname: this.props.action, state: {form :this.props.formdata} });
          }          
        }
     

  }

  linkFocus = (e) => {
    console.log('link focused');
    //$('.zb-cancel-primary').attr('style',' border:2px solid #1d7b8a;padding:3px;');
    $('.zb-cancel-primary').addClass('vb-link-focus');    //atatr('style',' border:2px solid #1d7b8a;padding:3px;');
  }

  linkBlur = (e) => {
    console.log('link blur');
    $('.zb-cancel-primary').removeClass('vb-link-focus');
  }

  cancelVideoBanking = (e) => {
    e.preventDefault();
    //alert('The link was clicked.');
    window.close();
  };
    render()
    {
      return (
            <nav className='zb-column-is-12'>
                <div className='zb-column-is-1'></div>
                <div className ="zb-column-is-11">
                <div className="vb-bottom-section">
                    
                    <div className= "vb-next-button"> 
                    <button
                         id={COPY.SUBMIT_BUTTON_TEXT}
                         value={COPY.SUBMIT_BUTTON_TEXT}
                        type="button"
                        className="zb-button-primary"
                        title={COPY.BUTTON_HELP_TEXT}
                        onClick={this.onSubmit}>
                        <div className="zb-next-text">{COPY.NEXT_BUTTON_TEXT}</div>
                    </button>
                    </div>
                    <div className= "vb-app-cancel">                    
                    <a href="#" onFocus={this.linkFocus} onBlur={this.linkBlur} onClick={this.cancelVideoBanking} role="button" title={COPY.CANCEL_BUTTON_TEXT} className="zb-cancel-primary">{COPY.CANCEL_BUTTON_TEXT} </a>
                    </div>

                </div>                  
                </div>
            </nav>                      
      );
    }
  
  }
export default withRouter(NavigationContainer);

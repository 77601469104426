export const VALIDATION_RULES = () => {
  return {
    vbprodlistRequired:{
      test: val => !val,
      messageCopyKey: 'PRODUCT_SELECTION_REQUIRED'
    },
    privcystmtRequired: {
      test: val => !val,
      messageCopyKey: 'PRIVACY_STMT_REQUIRED'
    },
    vbexistingcustRequired:{
      test: val => !val,
      messageCopyKey: 'EXISTING_CUSTOMER_REQUIRED'
    },
    titleRequired: {
      test: val => !val,
      messageCopyKey: 'TITLE_REQUIRED'
    },
    noteRequired: {
      test: val => !val || (val && val.trim() === ''),
      messageCopyKey: 'NOTE_REQUIRED'
    },
    firstNameRequired: {
      test: val => !val || (val && val.trim() === ''),
      messageCopyKey: 'FIRST_NAME_REQUIRED'
    },
    lastNameRequired: {
      test: val => !val || (val && val.trim() === ''),
      messageCopyKey: 'LAST_NAME_REQUIRED'
    },
    fullNameRequired:{
      test: val => !val || (val && val.trim() === ''),
      messageCopyKey: 'FULL_NAME_REQUIRED'
    },
    nameCharsRange: {
      test: val =>
        (val !== undefined && val.length < 2) ||
        (val !== undefined && val.length > 40),
      messageCopyKey: 'NAME_CHARS_RANGE'
    },
    nameInvalidChars: {
		//test: val => val && val.trim().length && !/^([a-zA-Z][a-zA-Z'][a-zA-Z0-9-']{0,13}\s*){1,3}$/.test(val),
		test: val => val && val.trim().length && !/^([a-zA-Z0-9-',\\.\\-\\&()]{0,13}\s*){1,5}$/.test(val),
      
      messageCopyKey: 'NAME_INVALID_CHARS'
    },
    postalCodeRequired: {
      test: val => !val,
      messageCopyKey: 'POSTAL_CODE_REQUIRED'
    },
    postalCodeInvalid: {
      test: (val) =>
      (val!== undefined)&&(val!== "")&& (val
          =!/^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2}))$/gim.test(val)),
          // ? !/^01[1-9]\d{8}$/gim.test(val)
          // : !/^08[1-9]\d{7}$/gim.test(val),
      messageCopyKey: 'POSTAL_CODE_INVALID'
    },
    contactNoRequired: {
      test: val => !val,
      messageCopyKey: 'CONTACT_NO_REQUIRED'
    },
    contactNoInvalid: {
       test: (val) =>
        (val!== undefined)&&(val!== "")&& (val
		=!/^(\+44\s?7\d{9}|\+44\s?7\d{3}\s?\d{6}|\+44\(0\)?7\d{8}|0044\s?7\d{9}|0044\?7d{9}|07\d{2}\s?\d{3}\s?\d{4}|07?\d{9})?$/gim.test(val)),
	
          // ? !/^07[1-9]\d{8}$/gim.test(val)
          // : !/^08[1-9]\d{7}$/gim.test(val),
      messageCopyKey: 'CONTACT_NO_INVALID'
    },
    emailRequired: {
      test: val => !val,
      messageCopyKey: 'EMAIL_REQUIRED'
    },
    emailMaxLength: {
      test: val => val !== undefined && val.length > 50,
      messageCopyKey: 'EMAIL_MAX_LENGTH'
    },
    emailInvalid: {
      test: val =>
        val &&
        !/^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([\w-+]{2,30}(?:\.[\w-+]{2})?)$/gim.test(
          val
        ),
      messageCopyKey: 'EMAIL_INVALID'
    },
    emailComma: {
      test: val => /,/.test(val),
      messageCopyKey: 'EMAIL_COMMA'
    },
    emailAtMissing: {
      test: val => val !== undefined && !/@/.test(val),
      messageCopyKey: 'EMAIL_AT_MISSING'
    },
    emailSpacing: {
      test: val => /\s/.test(val),
      messageCopyKey: 'EMAIL_SPACING'
    },
    prstRequired: {
      test: val => val === 'false',
      messageCopyKey: 'PRIVACY_STMT_REQUIRED'
    }
  };
};

export const RULES_MAP = {
  vbprodlist:['vbprodlistRequired'],
  privacyStmt: ['privcystmtRequired'],
  vbtitle: ['titleRequired'],
  vbfullName: ['fullNameRequired', 'nameCharsRange', 'nameInvalidChars'],
  vbphoneNumber: ['contactNoRequired','contactNoInvalid'],
  vbpostalCode: ['postalCodeInvalid'],
  vbexistingcust:['vbexistingcustRequired'],
  prst: ['prstRequired'],
  /*email: [
    // 'emailRequired',
    'emailMaxLength',
    'emailAtMissing',
    'emailComma',
    'emailSpacing',
    'emailInvalid'
  ]*/
  //notes: ['noteRequired']
};

export const validatePPSN = value => {
  value = value.toUpperCase();

  if (!/^(\d{7})([A-Z]{1,2})$/.test(value)) {
    return false;
  }

  const numericPart = value.match(/\d+/)[0],
    checksumCharacter = value.match(/[A-Z]+/)[0];

  let sum = 0;

  for (let itr = 0, factor = 8; itr < numericPart.length; itr++) {
    sum += numericPart[itr] * factor--;
  }

  checksumCharacter[1] &&
    (sum += ((checksumCharacter[1].charCodeAt() - 64) % 23) * 9);

  return sum % 23 === (checksumCharacter[0].charCodeAt() - 64) % 23;
};

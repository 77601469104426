import React from 'react';
import { BRAND_CONST } from './pages/containers/landingtext';
import * as CONFIG from './config';

export default function footer() {
  
  return (
    <footer className="vb-footer" >
      <div className="vb-footer-line"> </div>
      <div className = "vb-footer-content">
        <div className = 'vb-footer-cookie'> 
          <a href= {BRAND_CONST[CONFIG.data.BRAND]["cookiepolicylink"]} target="_blank" rel="noopener">Privacy &amp; Cookies</a>  
        </div>
        <div className = 'vb-footer-text'>
          ©&nbsp;2018 – {String(new Date().getFullYear())} {CONFIG.data.FOOTNOTE}
        </div>
      </div>

    </footer>
  );
}
